import React, { useContext, useEffect, useState } from 'react';
import { SideMenuItems, SideMenuPageList, HTTPResponse, ToastMessageType } from '../Helpers/Enums';
import { useNavigate } from 'react-router';
import { AddLocalStorageData, GetLocalStorageData, isStringEmpty } from '../Helpers/Utilities';
import { PatientService } from "../WebApiServices/Patient.service";
import { AppContext } from '../Contexts/AppContextProvider';


const SideBarMenu = (props) => {

  const [incompletePageIds, setIncompletePageIds] = useState([]);
  const activePageId = props.activePageId;
  const patientID = GetLocalStorageData("patientId") ?? 0;
  const navigate = useNavigate();
  const { ShowToast, ToggleLoader, HandleSessionTimeout } = useContext(AppContext);
  //const { isSubmitted, formIncomplete } = useContext(AppContext);
  let patientData = GetLocalStorageData("patient");
  patientData = patientData && JSON.parse(patientData);

  const submit = patientData?.submit ?? 0;
  const locked = patientData?.locked ?? false;
  // const pid = patientData?.patientId ?? 0;
  // const userId = GetLocalStorageData("userId") ?? 0;

  const [pageList, setPageList] = useState(SideMenuPageList);

  const loadConfig = GetLocalStorageData("loadConfig") ?? false;


  useEffect(() => {
    const FetchData = async () => {

      try {        
        AddLocalStorageData("loadConfig", false)


        const response1 = await PatientService.IsEligiblePatient(patientID);
        const isEligible = response1?.data ?? false;
        if (response1?.status === HTTPResponse.OK) {

          const response2 = await PatientService.GetPatientConfigData(patientID);//patient config data
          if (response2?.status === HTTPResponse.OK) {
            if (response2 != null) {
              const { data } = response2;

              const temp = SideMenuPageList.map((page) => ({
                ...page,
                disabled: page.id > 2 &&
                  (
                    (page.id === (SideMenuItems.Demographics) && !isEligible && data.pd === null) ||
                    (page.id === (SideMenuItems.Questionnaire) && data.pdsubmitted !== true) ||
                    (page.id === (SideMenuItems.HRU) && data.avasubmitted !== true) ||
                    (page.id === (SideMenuItems.ValidateSubmit) && data.hrusubmitted !== true)
                  ),
              }));

              setPageList(temp);

              //  if (data?.submit === 1) {
              const incompletePageIdsArr = []
              if (data.pd && data.pd !== 1) {// if (!data.pd || data.pd !== 1) {
                incompletePageIdsArr.push(SideMenuItems.Demographics);
              }
              if (data.ava && data.ava !== 1) {//!data.ava || 
                incompletePageIdsArr.push(SideMenuItems.Questionnaire);
              }
              if (data.hru && data.hru !== 1) {//!data.hru || 
                incompletePageIdsArr.push(SideMenuItems.HRU);
              }
              setIncompletePageIds(incompletePageIdsArr)
              //  }
            }
          }
          else if (response1?.status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
          }
        } 
        else if (response1?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      } catch (e) {
      }
    }
    
    FetchData();
  }, [loadConfig])


  /* <summary>
  date: 04-11-2023
  Name: AP
  description: Get all incomplete forms
  <summary>*/
  // useEffect(() => {
  //   debugger
  //   const FetchData = async () => {
  //     //submit=1 higlight incomplete forms else default color
  //     if (submit === 1) {
  //       ToggleLoader(true)
  //       await SetIncompleteForms();
  //       ToggleLoader(false);
  //     }
  //   }
  //   FetchData()
  // }, [submit])

  // const SetIncompleteForms = async () => {
  //   try {
  //     const incompletePageIdsArr = []
  //     const validMenuItems = await PatientService.GetPatientConfigData(patientID);//patient config data
  //     const { data } = validMenuItems;
  //     if (validMenuItems != null) {
  //       if (!data.pd && data.pd !== 1) {
  //         incompletePageIdsArr.push(SideMenuItems.Demographics);
  //       }
  //       if (!data.itpPriorAva && data.itpPriorAva !== 1) {
  //         incompletePageIdsArr.push(SideMenuItems.TreatmentHistory);
  //       }
  //       if (!data.ava && data.ava !== 1) {
  //         incompletePageIdsArr.push(SideMenuItems.Questionnaire);
  //       }
  //       if (!data.itpFollAva && data.itpFollAva !== 1) {
  //         incompletePageIdsArr.push(SideMenuItems.TreatmentHistoryAfterAVA);
  //       }
  //       if (!data.hru && data.hru !== 1) {
  //         incompletePageIdsArr.push(SideMenuItems.HRU);
  //       }
  //       setIncompletePageIds(incompletePageIdsArr)
  //     }
  //   } catch {

  //   }
  // }

  //SYJ - Handle Navigation with Highlight on Validate/Submit Click.
  /* <summary>
  date: 04-11-2023
  Name: AP
  description: Edited the function. Removed highlighting of incomplete forms 
      inside a useEffect since it wasn't showing up on first click.
  <summary>*/
  const HandleNavigate = async (pageUrl) => {

    try {
      if (patientID > 0) {
        //ToggleLoader(true);
        const response = await PatientService.IsEligiblePatient(patientID);
        const isEligible = response?.data ?? false;


        const ptArr = { ...patientData }
        AddLocalStorageData("patient", JSON.stringify(ptArr))

        if (isEligible) {
          navigate(pageUrl);

          //if (pageUrl.trim().includes("Validate")) {
          //  ToggleLoader(true)
          //await SetPatientSubmitValue();
          // }
        }
        else {
          if (pageUrl.trim().includes("PatientScreening") || pageUrl.trim().includes("Introduction")) {
            navigate(pageUrl);
          }
        }
        //ToggleLoader(false)
      }
    } catch {

    }
  }
  // const SetPatientSubmitValue = async () => {
  //   try {
  //     const payload = {
  //       pid: pid,
  //       userId: userId
  //     }
  //     const response = await PatientService.SetPatientSubmitValue(payload);
  //     if (response?.status === HTTPResponse.OK) {
  //       debugger
  //       // Set Patient Submit Value in LS
  //       const ptArr = { ...patientData }
  //       ptArr.submit = response?.data

  //       AddLocalStorageData("patient", JSON.stringify(ptArr))
  //     }
  //     else
  //       throw (response.error)

  //   } catch {
  //     ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
  //   }

  // }
  return (
    <div className="col-md-3 col-pad-right">
      <div className="tab">
        <div className="activity-link-tab">
          {
            pageList?.map((item) => (
              <button key={item.id}
                onClick={() => { HandleNavigate(item.path) }}
                className={`tablinks ${activePageId === item.id && "active"}`}
                style={{ color: incompletePageIds.includes(item.id) ? "red " : "white" }}
                disabled={item.disabled}>
                {/* || activePageId !== item.id && "session-incomplete */}

                {/* (patientID === 0 && item.id > 1) ||
                  (patientID > 0 && item.id > 2 && !isEligible) */}
                {(
                  (patientID === 0 && item.id > 1) ||
                  item.disabled
                )
                  && <img className="lock-icon" src="../Assets/images/locked.png" alt=""></img>}
                {item.id !== SideMenuItems.ValidateSubmit
                  ? <span>{item.title}</span>
                  : (<span>
                    {locked ? "SUBMITTED"
                      // : submit === 1 && incompletePageIds.length === 0
                      //   ? "Submit Patient" : item.title
                      : item.title
                    }
                  </span>)
                }
              </button>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default SideBarMenu