import React, { createContext, useEffect, useReducer, useRef, useState } from "react";
import { AppReducer } from "../Reducers/AppReducer";
import {
  AppContextIntialState,
  HTTPResponse,
  ToastMessageType,
  ToastTimeout,
  TopMenuItems,
} from "../Helpers/Enums";
import Loader from "../Components/Loader";
import Toast from "../Components/Toast";
import { AppReducerActions } from "../ReducerActions/AppReducerActions";
import ActionPopUp from "../Components/ActionPopUp";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import TopMenu from "../Components/TopMenu";
import { CommonMsgs } from "../Helpers/HelperText";
import { GetLoggedInUserID, isStringEmpty } from "../Helpers/Utilities";
import { UserLoginService } from "../WebApiServices/UserLogin.service";

export const AppContext = createContext();
const AppContextProvider = (props) => {
  const [appState, DispatchAppContextAction] = useReducer(AppReducer, {
    ...AppContextIntialState,
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();


  //ref to resolve the confirm action
  const confirmResolveFunction = useRef();
  /* <summary>
  date: 16-12-2022
  Name: GM
  description: EffectFD to hide toast after 4 seconds
  <summary>*/
  useEffect(() => {
    if (appState.showToast) {
      setTimeout(() => {
        DispatchAppContextAction({
          type: AppReducerActions.SHOW_TOAST,
          message: "",
        });
      }, ToastTimeout);
    }

    return () => { };
  }, [appState]);
  /* <summary>
  date: 08-08-2023
  Name: AP
  description: Scroll to top of page when it loads
  <summary>*/
  useEffect(() => {
    //Routes to consider when showing Top Menu
    const includedRoutes = ["/managepatients", "/manageabstractors", "/managecenters",
      "/introduction", "/patientscreening", "/demographics", "/questionnaire", "/healthcare",
      "/validatesubmit"];
    window.scrollTo(0, 0);
    if (!isStringEmpty(pathname) && includedRoutes.includes(pathname.toLowerCase())) {
      ShowTopMenu(true);

      if (pathname.toLowerCase().includes("/managecenters"))
        SetActiveTab(TopMenuItems.Centers);
      else if (pathname.toLowerCase().includes("/manageabstractors"))
        SetActiveTab(TopMenuItems.Abstractors);
      else SetActiveTab(TopMenuItems.Patients);
    } else ShowTopMenu(false);

    // Authenticate logged in user Token
    async function Authenticate() {
      const response = await UserLoginService.AuthenticateJWTToken();
      if (response?.status === HTTPResponse.Unauthorized) {
        await HandleSessionTimeout()
      }
    }
    GetLoggedInUserID() && Authenticate();

  }, [pathname]);

  /* <summary>
  date: 08-11-2023
  Name: AP
  description: Show message n Redirect to login page once session times out
  <summary>*/
  const HandleSessionTimeout = async () => {
      ToggleLoader(false)
    const choice = await ShowActionPopUp(
      "",
      CommonMsgs.SessionTimeout,
      "Ok",
      true
    );
    if (choice) {
      localStorage.clear();
      navigate("/");
    }
  };

  /* <summary>
  date: 16-12-2022
  Name: GM
  description: Toggle UI Loader
  <summary>*/
  function ToggleLoader(action) {
    DispatchAppContextAction({
      type: AppReducerActions.TOGGLE_LOADER,
      value: action,
    });
  }

  function ShowToast(message, toastType) {
    DispatchAppContextAction({
      type: AppReducerActions.SHOW_TOAST,
      message,
      toastType,
    });
  }
  /* <summary>
  date: 27-04-2023
  Name: GM
  description: Method to show confirm modal and resolve the action
  <summary>*/
  const ShowActionPopUp = (
    title,
    description,
    confirmButtonLabel,
    disableCancelBtn
  ) => {
    return new Promise((resolve) => {
      DispatchAppContextAction({
        type: AppReducerActions.SHOW_ACTIONPOPUP,
        title,
        description,
        confirmButtonLabel,
        disableCancelBtn,
      });
      confirmResolveFunction.current = (choice) => {
        resolve(choice);
        DispatchAppContextAction({
          type: AppReducerActions.CLOSE_ACTIONPOPUP,
        });
      };
    });
  };

  const OnConfirmModalCancelClicked = () => {
    confirmResolveFunction.current(false);
  };

  const OnConfirmModalConfirmClicked = () => {
    confirmResolveFunction.current(true);
  };
  /* <summary>
  date: 10-08-2023
  Name: AP
  description: Toggle top menu
  <summary>*/
  const ShowTopMenu = (action) => {
    DispatchAppContextAction({
      type: AppReducerActions.SHOW_TOPMENU,
      value: action,
    });
  };
  /* <summary>
  date: 16-08-2023
  Name: AP
  description: Set active tab to highlight in top menu
  <summary>*/
  const SetActiveTab = (action) => {
    DispatchAppContextAction({
      type: AppReducerActions.SET_ACTIVETAB,
      value: action,
    });
  };

  //const pid = GetLocalStorageData("patientId") ?? 0;
  // useEffect(() => {

  //   const FetchData = async () => {
  //     await SetPatientConfigData();
  //   }
  //   FetchData();
  // }, [pid])
  /* <summary>
  date: 20-11-2023
  Name: AP
  description: Set Patient config values
  <summary>*/
  // const SetPatientConfigData = async () => {
  //   const response = await PatientService.GetPatientConfigData(patientID);

  //   DispatchAppContextAction({
  //     type: AppReducerActions.SET_ACTIVETAB,
  //     value: action,
  //   });
  // };

  const { isLoading, showToast, toastMessage, actionPopUp, showTopMenu } =
    appState;
  return (
    <AppContext.Provider
      value={{
        appState,
        ToggleLoader,
        ShowToast,
        ShowActionPopUp,
        ShowTopMenu,
        SetActiveTab,
        HandleSessionTimeout

      }}
    >
      <>
        {isLoading && <Loader />}
        {showToast && toastMessage && <Toast />}
        {actionPopUp.isOpen && (
          <ActionPopUp
            onCancel={OnConfirmModalCancelClicked}
            OnConfirmed={OnConfirmModalConfirmClicked}
          />
        )}
        <main style={pathname === "/" ? { overflowY: "hidden" } : {}}>
          <Header />
          {showTopMenu && <TopMenu />}
          {props.children}
          {<Footer isLoginPage={pathname.toLowerCase() === "/"} />}
        </main>
      </>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
