import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./custom.css";
import PrivateRoute from "./Components/PrivateRoute";
import Login from "./Pages/Login";
import Error from "./Pages/Error";
import AppContextProvider from "./Contexts/AppContextProvider";

import Dashboard from "./Pages/Dashboard";
import Introduction from "./Pages/Patient/Introduction";
import PatientScreening from "./Pages/Patient/PatientScreening";
import Questionnaire from "./Pages/Patient/Questionnaire";
import Healthcare from "./Pages/Patient/Healthcare";
import Demographics from "./Pages/Patient/Demographics";
import ManageAbstractors from "./Pages/Abstractor/ManageAbstractors";
import { ManageCenters } from "./Pages/Center/ManageCenters";
import ResetPassword from "./Pages/ResetPassword";
import PasswordRecovery from "./Pages/PasswordRecovery";
import ValidateSubmit from "./Pages/Patient/ValidateSubmit";
import { AddLocalStorageData, GetLocalStorageData } from "./Helpers/Utilities";

const App = () => {
  const location = useLocation();

  /* <summary>
date: 04-11-2023
Name: AP(code from doc created by GM)
description: Effect to clear the cache
<summary>*/

  // useEffect(() => {
  //   const Caching = () => {
  //     const version = GetLocalStorageData("version");

  //     //console.log("Package version", version);

  //     if (version !== packageJson.version) {
  //       //console.log("Clearing cache");

  //       AddLocalStorageData("version", packageJson.version);

  //       if ("caches" in window) {
  //         caches.keys().then((names) => {
  //           // Delete all the cache files

  //           names.forEach((name) => {
  //             caches.delete(name);
  //           });
  //         });
  //       }

  //       // Makes sure the page reloads. Changes are only visible after you refresh.

  //       window.location.reload(true);
  //     }
  //   };

  //   Caching();

  //   return () => { };
  // }, []);

  return (
    <AppContextProvider>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Login />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/PasswordRecovery" element={<PasswordRecovery />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/ManagePatients" element={<Dashboard />} />
          <Route path="/ManageAbstractors" element={<ManageAbstractors />} />
          <Route path="/ManageCenters" element={<ManageCenters />} />
          <Route path="/Introduction" element={<Introduction />} />
          <Route path="/PatientScreening" element={<PatientScreening />} />
          <Route path="/Questionnaire" element={<Questionnaire />} />
          <Route path="/Healthcare" element={<Healthcare />} />
          <Route path="/Demographics" element={<Demographics />} />
          <Route path="/ValidateSubmit" element={<ValidateSubmit />} />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </AppContextProvider>
  );
};
export default App;
