import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import SideBarMenu from "../../Components/SideBarMenu";
import { CommonError_Msgs, HRU_Msgs } from "../../Helpers/HelperText";
import DateControl from "../../Components/DateControl";
import ErrorField from "../../Components/ErrorField";
import { HealthcareService } from "../../WebApiServices/Healthcare.service";
import { AppContext } from '../../Contexts/AppContextProvider';
import { useNavigate, useParams } from "react-router";
import {
    GetLocalStorageData,
    SubtractMonths,
    GetLoggedInUserID,
    GetMininumDate,
} from "../../Helpers/Utilities";
import {
    HTTPResponse,
    PageDesignConstants,
    SideMenuItems,
    ToastMessageType,
    ToastMessages,
} from "../../Helpers/Enums";
const NUMBER_REGEX = /^[0-9]{0,9}$/;

const Healthcare = () => {

    const pid = GetLocalStorageData("patientId") ?? 0;
    const navigatePaths = { prevPage: "/Questionnaire", currPage: "/Healthcare", nextPage: "/ValidateSubmit", };
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    const [showTopErrMsg, setShowTopErrMsg] = useState(false);
    const [indexDateMinus3Months, setindexDateMinus3Months] = useState("");
    const [endfollowupdate, setendfollowupdate] = useState("");
    const [avadiscdate, setavadiscdate] = useState("");
    const [minenddiscdate, setminenddiscdate] = useState("");
    const [showq45repeater, setshowq45repeater] = useState(false);
    const [showq46repeater, setshowq46repeater] = useState(false);
    const [showq47repeater, setshowq47repeater] = useState(false);
    const { ShowToast, ToggleLoader, HandleSessionTimeout } = useContext(AppContext)

    const [hruDetails, sethruDetails] = useState(
        {
            hruid: 0,
            hru_ip_use: "",
            hru_er_use: "",
            hru_op_use: "",
            q45Empty: false,
            q46Empty: false,
            q47Empty: false,
        },
    );

    const [itphospitalizationDetails, setitphospitalizationDetails] = useState([
        {
            Itphospitalizationid: 0,

            ip_admit_n1_dt: "",
            ip_admit_n1_dt_unk: "",
            defDateQ45Admit: false,
            q45Admitdatechange: false,

            ip_disc_n1_dt: "",
            ip_disc_n1_dt_unk: "",
            defDateQ45Disc: false,
            q45Discdatechange: false,

            q45aDateEmpty: false,
            q45aDateInvalidSoftError1: false,
            q45aDateInvalidSoftError2: false,
            q45aDateInvalidSoftError3: false,
            q45aUnkEmpty: false,
            q45bDateEmpty: false,
            q45bDateInvalidSoftError1: false,
            q45bDateInvalidSoftError2: false,
            q45bUnkEmpty: false,
        },
    ]);
    const [itpemergencyvisitsDetails, setitpemergencyvisitsDetails] = useState([
        {
            Itpemergencyvisitsid: 0,
            er_n1_dt: "",
            er_n2_dt_unk: "",
            defDateQ46: false,
            q46datechange: false,
            q46DateEmpty: false,
            q46DateInvalidSoftError1: false,
            q46DateInvalidSoftError2: false,
            q46DateInvalidSoftError3: false,
            q46UnkEmpty: false,
        },
    ]);
    const [itpoutpatientvisitsDetails, setitpoutpatientvisitsDetails] = useState([
        {
            Itpoutpatientvisitsid: 0,
            op_n1_dt: "",
            op_n2_dt_unk: "",
            defDateQ47: false,
            q47datechange: false,
            q47DateEmpty: false,
            q47DateInvalidSoftError1: false,
            q47DateInvalidSoftError2: false,
            q47DateInvalidSoftError3: false,
            q47UnkEmpty: false,
        },
    ]);

    useEffect(() => {

        async function FetchData() {
            if (!isNaN(pid) && Number(pid) !== 0) {

                ToggleLoader(true);
                await GetHealthcareData();
                ToggleLoader(false);
            }
        }
        FetchData();

        //GetHealthcareData();
    }, []);

    const HandleDateChange = (name, date, optionId, index) => {
        debugger;
        const lastIndex = name.lastIndexOf("-");
        const idx = name.slice(lastIndex + 1);
        switch (optionId) {
            case "ip_admit_n1_dt":
                {
                    let Q45Admitarray = [...itphospitalizationDetails];
                    Q45Admitarray[idx][optionId] = date;
                    Q45Admitarray[idx].ip_admit_n1_dt_unk = "";
                    Q45Admitarray[idx].defDateQ45Admit = false;
                    Q45Admitarray[idx].q45Admitdatechange = false;
                    Q45Admitarray[idx].q45aDateEmpty = false;
                    Q45Admitarray[idx].q45aDateInvalidSoftError1 = indexDateMinus3Months == "" ? false : new Date(itphospitalizationDetails[idx].ip_admit_n1_dt) < new Date(indexDateMinus3Months);//q36x1_ip_admit_dt < (index_date - 3 months)
                    Q45Admitarray[idx].q45aDateInvalidSoftError2 = avadiscdate == "" ? false : new Date(itphospitalizationDetails[idx].ip_admit_n1_dt) > new Date(avadiscdate);//q36x1_ip_admit_dt > ava_disc_dt
                    Q45Admitarray[idx].q45aDateInvalidSoftError3 = endfollowupdate == "" ? false : new Date(itphospitalizationDetails[idx].ip_admit_n1_dt) > new Date(endfollowupdate);//q36x1_ip_admit_dt > end_of_followup
                    Q45Admitarray[idx].q45aUnkEmpty = false;
                    setitphospitalizationDetails(Q45Admitarray);
                }
                break;
            case "ip_disc_n1_dt":
                {
                    let Q45Discarray = [...itphospitalizationDetails];
                    Q45Discarray[idx][optionId] = date;
                    Q45Discarray[idx].ip_disc_n1_dt_unk = "";
                    Q45Discarray[idx].defDateQ45Disc = false;
                    Q45Discarray[idx].q45Discdatechange = false;
                    Q45Discarray[idx].q45bDateEmpty = false;
                    Q45Discarray[idx].q45bUnkEmpty = false;

                    Q45Discarray[idx].q45bDateInvalidSoftError1 = itphospitalizationDetails[idx].ip_admit_n1_dt == "" ? false : new Date(itphospitalizationDetails[idx].ip_disc_n1_dt) < new Date(itphospitalizationDetails[idx].ip_admit_n1_dt);//q36x1_ip_disc_dt < q36x1_ip_admit_dt

                    Q45Discarray[idx].q45bDateInvalidSoftError2 = endfollowupdate == "" ? false : new Date(itphospitalizationDetails[idx].ip_disc_n1_dt) > new Date(endfollowupdate);//q36x1_ip_disc_dt > end_of_followup

                    setitphospitalizationDetails(Q45Discarray);
                }
                break;
            case "er_n1_dt":
                {
                    let Q46array = [...itpemergencyvisitsDetails];
                    Q46array[idx][optionId] = date;
                    Q46array[idx].er_n2_dt_unk = "";
                    Q46array[idx].defDateQ46 = false;
                    Q46array[idx].q46datechange = false;
                    Q46array[idx].q46DateEmpty = false;
                    Q46array[idx].q46DateInvalidSoftError1 = indexDateMinus3Months == "" ? false : new Date(itpemergencyvisitsDetails[idx].er_n1_dt) < new Date(indexDateMinus3Months);//q37x1_er_dt < (index_date - 3 months)
                    Q46array[idx].q46DateInvalidSoftError2 = avadiscdate == "" ? false : new Date(itpemergencyvisitsDetails[idx].er_n1_dt) > new Date(avadiscdate);//q37x1_er_dt > ava_disc_dt
                    Q46array[idx].q46DateInvalidSoftError3 = endfollowupdate == "" ? false : new Date(itpemergencyvisitsDetails[idx].er_n1_dt) > new Date(endfollowupdate);//q37x1_er_dt > end_of_followup
                    Q46array[idx].q46UnkEmpty = false;
                    setitpemergencyvisitsDetails(Q46array);
                }
                break;
            case "op_n1_dt":
                {
                    let Q47array = [...itpoutpatientvisitsDetails];
                    Q47array[idx][optionId] = date;
                    Q47array[idx].op_n2_dt_unk = "";
                    Q47array[idx].defDateQ47 = false;
                    Q47array[idx].q47datechange = false;
                    Q47array[idx].q47DateEmpty = false;
                    Q47array[idx].q47DateInvalidSoftError1 = indexDateMinus3Months == "" ? false : new Date(itpoutpatientvisitsDetails[idx].op_n1_dt) < new Date(indexDateMinus3Months);//q38x1_op_dt < (index_date - 3 months)
                    Q47array[idx].q47DateInvalidSoftError2 = avadiscdate == "" ? false : new Date(itpoutpatientvisitsDetails[idx].op_n1_dt) > new Date(avadiscdate);//q38x1_op_dt > ava_disc_dt
                    Q47array[idx].q47DateInvalidSoftError3 = endfollowupdate == "" ? false : new Date(itpoutpatientvisitsDetails[idx].op_n1_dt) > new Date(endfollowupdate);//q38x1_op_dt > end_of_followup
                    Q47array[idx].q47UnkEmpty = false;
                    setitpoutpatientvisitsDetails(Q47array);
                }
                break;
            default:
                break;
        }
    };

    const HandleQ45AdmitDatechangeunk = (e) => {
        debugger;
        const { name, id, value } = e.target;
        let Q45array = [...itphospitalizationDetails];
        Q45array[id].ip_admit_n1_dt = "";
        Q45array[id].ip_admit_n1_dt_unk = value;
        Q45array[id].defDateQ45Admit = true;
        Q45array[id].q45Admitdatechange = false;
        Q45array[id].q45aDateEmpty = false;
        Q45array[id].q45aDateInvalidSoftError1 = false;
        Q45array[id].q45aDateInvalidSoftError2 = false;
        Q45array[id].q45aDateInvalidSoftError3 = false;
        Q45array[id].q45aUnkEmpty = false;
        setitphospitalizationDetails(Q45array);
    };

    const HandleQ45DiscDatechangeunk = (e) => {
        debugger;
        const { name, id, value } = e.target;
        let Q45array = [...itphospitalizationDetails];
        Q45array[id].ip_disc_n1_dt = "";
        Q45array[id].ip_disc_n1_dt_unk = value;
        Q45array[id].defDateQ45Disc = true;
        Q45array[id].q45Discdatechange = false;
        Q45array[id].q45bDateEmpty = false;
        Q45array[id].q45bDateInvalidSoftError1 = false;
        Q45array[id].q45bDateInvalidSoftError2 = false;
        Q45array[id].q45bUnkEmpty = false;
        setitphospitalizationDetails(Q45array);
    };

    const HandleQ46Datechangeunk = (e) => {
        debugger;
        const { name, id, value } = e.target;
        let Q46array = [...itpemergencyvisitsDetails];
        Q46array[id].er_n1_dt = "";
        Q46array[id].er_n2_dt_unk = value;
        Q46array[id].defDateQ46 = true;
        Q46array[id].q46datechange = false;
        Q46array[id].q46DateEmpty = false;
        Q46array[id].q46DateInvalidSoftError1 = false;
        Q46array[id].q46DateInvalidSoftError2 = false;
        Q46array[id].q46DateInvalidSoftError3 = false;
        Q46array[id].q46UnkEmpty = false;
        setitpemergencyvisitsDetails(Q46array);
    };

    const HandleQ47Datechangeunk = (e) => {
        debugger;
        const { name, id, value } = e.target;
        let Q47array = [...itpoutpatientvisitsDetails];
        Q47array[id].op_n1_dt = "";
        Q47array[id].op_n2_dt_unk = value;
        Q47array[id].defDateQ47 = true;
        Q47array[id].q47datechange = false;
        Q47array[id].q47DateEmpty = false;
        Q47array[id].q47DateInvalidSoftError1 = false;
        Q47array[id].q47DateInvalidSoftError2 = false;
        Q47array[id].q47DateInvalidSoftError3 = false;
        Q47array[id].q47UnkEmpty = false;
        setitpoutpatientvisitsDetails(Q47array);
    };

    const HandleradioChange = (e) => {
        debugger;
        const { name, id, value } = e.target;
        switch (id) {
            case "hru_ip_use":
                {
                    if (value == 1)
                        setshowq45repeater(true);
                    else {
                        setshowq45repeater(false);
                        setitphospitalizationDetails([{
                            Itphospitalizationid: 0,
                            ip_admit_n1_dt: "",
                            ip_admit_n1_dt_unk: "",
                            defDateQ45Admit: false,
                            q45Admitdatechange: false,
                            ip_disc_n1_dt: "",
                            ip_disc_n1_dt_unk: "",
                            defDateQ45Disc: false,
                            q45Discdatechange: false,
                            q45aDateEmpty: false,
                            q45aDateInvalidSoftError1: false,
                            q45aDateInvalidSoftError2: false,
                            q45aDateInvalidSoftError3: false,
                            q45aUnkEmpty: false,
                            q45bDateInvalidSoftError1: false,
                            q45bDateInvalidSoftError2: false,
                            q45bDateEmpty: false,
                            q45bUnkEmpty: false,

                        }]);
                    }

                    sethruDetails((prev) => ({
                        ...prev,
                        hru_ip_use: value,
                    }));
                }
                break;
            case "hru_er_use":
                {
                    if (value == 1)
                        setshowq46repeater(true);
                    else {
                        setshowq46repeater(false);
                        setitpemergencyvisitsDetails([{
                            Itpemergencyvisitsid: 0,
                            er_n1_dt: "",
                            er_n2_dt_unk: "",
                            defDateQ46: false,
                            q46datechange: false,
                            q46DateEmpty: false,
                            q46DateInvalidSoftError1: false,
                            q46DateInvalidSoftError2: false,
                            q46DateInvalidSoftError3: false,
                            q46UnkEmpty: false,
                        }]);
                    }

                    sethruDetails((prev) => ({
                        ...prev,
                        hru_er_use: value,
                    }));
                }
                break;
            case "hru_op_use":
                {
                    if (value == 1)
                        setshowq47repeater(true);
                    else {
                        setshowq47repeater(false);
                        setitpoutpatientvisitsDetails([{
                            Itpoutpatientvisitsid: 0,
                            op_n1_dt: "",
                            op_n2_dt_unk: "",
                            defDateQ47: false,
                            q47datechange: false,
                            q47DateEmpty: false,
                            q47DateInvalidSoftError1: false,
                            q47DateInvalidSoftError2: false,
                            q47DateInvalidSoftError3: false,
                            q47UnkEmpty: false,
                        }]);
                    }

                    sethruDetails((prev) => ({
                        ...prev,
                        hru_op_use: value,
                    }));
                }
                break;
            default:
                break;
        }
    }

    const GetHealthcareData = async () => {
        debugger;
        try {
            debugger;
            const response = await HealthcareService.GetHealthcareData(pid);
            if (response?.status !== HTTPResponse.OK) {
                if (response?.status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else
                    throw response.error;
            } else {
                setindexDateMinus3Months(response.data.indexDateMinus3Months);
                setendfollowupdate(response.data.endFollowUpDate == null ? "" : response.data.endFollowUpDate);
                setavadiscdate(response.data.avaDiscDate == null ? "" : response.data.avaDiscDate);
                debugger;
                let enddt = response.data.endFollowUpDate == null ? "" : response.data.endFollowUpDate;
                let discdt = response.data.avaDiscDate == null ? "" : response.data.avaDiscDate;
                let mindt;
                const listOfDates = [enddt, discdt];
                if (enddt == "" && discdt != "")
                    mindt = discdt
                else if (enddt != "" && discdt == "")
                    mindt = enddt
                else if (enddt == "" && discdt == "")
                    mindt = "[min (ava_disc_date, end_of_followup)]"
                else if (enddt != "" && discdt != "") {
                    let enddateObject = new Date(enddt);
                    let discdateObject = new Date(discdt);
                    if (enddateObject > discdateObject)
                        mindt = discdt;
                    else
                        mindt = enddt;
                }
                setminenddiscdate(mindt);
                debugger;
                if (response.data.hruDetails != null) {
                    sethruDetails({
                        ...hruDetails,
                        hruid: response.data.hruDetails.hruid != null ? response.data.hruDetails.hruid : 0,
                        hru_ip_use: response.data.hruDetails.hru_ip_use != null ? response.data.hruDetails.hru_ip_use : "",
                        hru_er_use: response.data.hruDetails.hru_er_use != null ? response.data.hruDetails.hru_er_use : "",
                        hru_op_use: response.data.hruDetails.hru_op_use != null ? response.data.hruDetails.hru_op_use : "",
                        q45Empty: false,
                        q46Empty: false,
                        q47Empty: false
                    });
                    debugger;
                    if (response.data.hruDetails.hru_ip_use == 1)
                        setshowq45repeater(true);
                    if (response.data.hruDetails.hru_er_use == 1)
                        setshowq46repeater(true);
                    if (response.data.hruDetails.hru_op_use == 1)
                        setshowq47repeater(true);
                }

                if (
                    response.data.itphospitalizationDetails != null &&
                    response.data.itphospitalizationDetails.length > 0
                ) {
                    let i = 0;
                    let dataarray = [];
                    for (i; i < response.data.itphospitalizationDetails.length; i++) {
                        let datacomponent = {
                            Itphospitalizationid:
                                response.data.itphospitalizationDetails[i].itphospitalizationid != null
                                    ? response.data.itphospitalizationDetails[i].itphospitalizationid
                                    : 0,
                            ip_admit_n1_dt:
                                response.data.itphospitalizationDetails[i].ip_admit_n1_dt != null
                                    ? response.data.itphospitalizationDetails[i].ip_admit_n1_dt
                                    : "",
                            ip_admit_n1_dt_unk:
                                response.data.itphospitalizationDetails[i].ip_admit_n1_dt_unk === true
                                    ? "1"
                                    : "",
                            q45Admitdatechange: false,
                            ip_disc_n1_dt:
                                response.data.itphospitalizationDetails[i].ip_disc_n1_dt != null
                                    ? response.data.itphospitalizationDetails[i].ip_disc_n1_dt
                                    : "",
                            ip_disc_n1_dt_unk:
                                response.data.itphospitalizationDetails[i].ip_disc_n1_dt_unk === true
                                    ? "1"
                                    : "",
                            q45Discdatechange: false,
                            q45aDateEmpty: false,
                            q45aDateInvalidSoftError1: false,
                            q45aDateInvalidSoftError2: false,
                            q45aDateInvalidSoftError3: false,
                            q45aUnkEmpty: false,
                            q45bDateEmpty: false,
                            q45bDateInvalidSoftError1: false,
                            q45bDateInvalidSoftError2: false,
                            q45bUnkEmpty: false,
                        };
                        dataarray.push(datacomponent);
                    }
                    debugger;
                    setitphospitalizationDetails(dataarray);
                }

                if (
                    response.data.itpemergencyvisitsDetails != null &&
                    response.data.itpemergencyvisitsDetails.length > 0
                ) {
                    let i = 0;
                    let dataarray = [];
                    for (i; i < response.data.itpemergencyvisitsDetails.length; i++) {
                        let datacomponent = {
                            Itpemergencyvisitsid:
                                response.data.itpemergencyvisitsDetails[i].itpemergencyvisitsid != null
                                    ? response.data.itpemergencyvisitsDetails[i].itpemergencyvisitsid
                                    : 0,
                            er_n1_dt:
                                response.data.itpemergencyvisitsDetails[i].er_n1_dt != null
                                    ? response.data.itpemergencyvisitsDetails[i].er_n1_dt
                                    : "",
                            er_n2_dt_unk:
                                response.data.itpemergencyvisitsDetails[i].er_n2_dt_unk === true
                                    ? "1"
                                    : "",
                            q46datechange: false,
                            q46DateEmpty: false,
                            q46DateInvalidSoftError1: false,
                            q46DateInvalidSoftError2: false,
                            q46DateInvalidSoftError3: false,
                            q46UnkEmpty: false,
                        };
                        dataarray.push(datacomponent);
                    }
                    debugger;
                    setitpemergencyvisitsDetails(dataarray);
                }

                if (
                    response.data.itpoutpatientvisitsDetails != null &&
                    response.data.itpoutpatientvisitsDetails.length > 0
                ) {
                    let i = 0;
                    let dataarray = [];
                    for (i; i < response.data.itpoutpatientvisitsDetails.length; i++) {
                        let datacomponent = {
                            Itpoutpatientvisitsid:
                                response.data.itpoutpatientvisitsDetails[i].itpoutpatientvisitsid != null
                                    ? response.data.itpoutpatientvisitsDetails[i].itpoutpatientvisitsid
                                    : 0,
                            op_n1_dt:
                                response.data.itpoutpatientvisitsDetails[i].op_n1_dt != null
                                    ? response.data.itpoutpatientvisitsDetails[i].op_n1_dt
                                    : "",
                            op_n2_dt_unk:
                                response.data.itpoutpatientvisitsDetails[i].op_n2_dt_unk === true
                                    ? "1"
                                    : "",
                            q47datechange: false,
                            q47DateEmpty: false,
                            q47DateInvalidSoftError1: false,
                            q47DateInvalidSoftError2: false,
                            q47DateInvalidSoftError3: false,
                            q47UnkEmpty: false,
                        };
                        dataarray.push(datacomponent);
                    }
                    debugger;
                    setitpoutpatientvisitsDetails(dataarray);
                }
            }
        }
        catch {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);

        }
    }

    function addItphospitalizationRow(e) {
        let index = itphospitalizationDetails.length;
        setitphospitalizationDetails((prev) => [
            ...prev,
            {
                Itphospitalizationid: 0,
                ip_admit_n1_dt: "",
                ip_admit_n1_dt_unk: "",
                ip_disc_n1_dt: "",
                ip_disc_n1_dt_unk: "",
                defDateQ45Admit: false,
                q45Admitdatechange: false,
                defDateQ45Disc: false,
                q45Discdatechange: false,
                q45aDateEmpty: false,
                q45aDateInvalidSoftError1: false,
                q45aDateInvalidSoftError2: false,
                q45aDateInvalidSoftError3: false,
                q45aUnkEmpty: false,
                q45bDateInvalidSoftError1: false,
                q45bDateInvalidSoftError2: false,
                q45bDateEmpty: false,
                q45bUnkEmpty: false,
            },
        ]);
    }

    function additpemergencyvisitsDetailsRow(e) {
        let index = itpemergencyvisitsDetails.length;
        setitpemergencyvisitsDetails((prev) => [
            ...prev,
            {
                Itpemergencyvisitsid: 0,
                er_n1_dt: "",
                er_n2_dt_unk: "",
                defDateQ46: false,
                q46datechange: false,
                q46DateEmpty: false,
                q46DateInvalidSoftError1: false,
                q46DateInvalidSoftError2: false,
                q46DateInvalidSoftError3: false,
                q46UnkEmpty: false,
            },
        ]);
    }

    function additpoutpatientvisitsDetailsRow(e) {
        debugger;
        let index = itpoutpatientvisitsDetails.length;
        setitpoutpatientvisitsDetails((prev) => [
            ...prev,
            {
                Itpoutpatientvisitsid: 0,
                op_n1_dt: "",
                op_n2_dt_unk: "",
                defDateQ47: false,
                q47datechange: false,
                q47DateEmpty: false,
                q47DateInvalidSoftError1: false,
                q47DateInvalidSoftError2: false,
                q47DateInvalidSoftError3: false,
                q47UnkEmpty: false,
            },
        ]);
    }

    function deleteitphospitalizationDetails(id) {
        const newitphospitalizationDetailsArray = [...itphospitalizationDetails];
        newitphospitalizationDetailsArray.splice(id, 1);
        let ii = newitphospitalizationDetailsArray;
        setitphospitalizationDetails(newitphospitalizationDetailsArray);
    }

    function deleteitpemergencyvisitsDetails(id) {
        const newitpemergencyvisitsDetailsArray = [...itpemergencyvisitsDetails];
        newitpemergencyvisitsDetailsArray.splice(id, 1);
        let ii = newitpemergencyvisitsDetailsArray;
        setitpemergencyvisitsDetails(newitpemergencyvisitsDetailsArray);
    }

    function deleteitpoutpatientvisitsDetails(id) {
        debugger;
        const newitpoutpatientvisitsDetailsArray = [...itpoutpatientvisitsDetails];
        newitpoutpatientvisitsDetailsArray.splice(id, 1);
        let ii = newitpoutpatientvisitsDetailsArray;
        setitpoutpatientvisitsDetails(newitpoutpatientvisitsDetailsArray);
    }

    function validate() {
        debugger;
        let q45Empty = hruDetails.hru_ip_use === "" ? true : false;
        let q46Empty = hruDetails.hru_er_use === "" ? true : false;
        let q47Empty = hruDetails.hru_op_use === "" ? true : false;
        sethruDetails((prev) => ({
            ...prev,
            q45Empty: q45Empty,
            q46Empty: q46Empty,
            q47Empty: q47Empty,
        }));

        let hasError = false;

        let itphospitalizationarray = itphospitalizationDetails;
        if (hruDetails.hru_ip_use === "1" || hruDetails.hru_ip_use === 1) {
            for (let i = 0; i < itphospitalizationDetails.length; i++) {
                debugger;
                itphospitalizationarray[i].q45aDateEmpty = itphospitalizationDetails[i].ip_admit_n1_dt === "";
                itphospitalizationarray[i].q45aUnkEmpty = itphospitalizationDetails[i].ip_admit_n1_dt_unk === "";
                itphospitalizationarray[i].q45bDateEmpty = itphospitalizationDetails[i].ip_disc_n1_dt === "";
                itphospitalizationarray[i].q45bUnkEmpty = itphospitalizationDetails[i].ip_disc_n1_dt_unk === "";
                itphospitalizationarray[i].q45aDateInvalidSoftError1 = false;
                itphospitalizationarray[i].q45aDateInvalidSoftError2 = false;
                itphospitalizationarray[i].q45aDateInvalidSoftError3 = false;
                itphospitalizationarray[i].q45bDateInvalidSoftError1 = false;
                itphospitalizationarray[i].q45bDateInvalidSoftError2 = false;

                if ((itphospitalizationarray[i].q45aDateEmpty && itphospitalizationarray[i].q45aUnkEmpty) || (itphospitalizationarray[i].q45bDateEmpty && itphospitalizationarray[i].q45bUnkEmpty))
                    hasError = true;
            }
            setitphospitalizationDetails(itphospitalizationarray);
        }

        let itpemergencyvisitsarray = itpemergencyvisitsDetails;
        if (hruDetails.hru_er_use === "1" || hruDetails.hru_er_use === 1) {
            for (let i = 0; i < itpemergencyvisitsDetails.length; i++) {
                debugger;
                itpemergencyvisitsarray[i].q46DateEmpty = itpemergencyvisitsDetails[i].er_n1_dt === "";
                itpemergencyvisitsarray[i].q46UnkEmpty = itpemergencyvisitsDetails[i].er_n2_dt_unk === "";
                itpemergencyvisitsarray[i].q46DateInvalidSoftError1 = false;
                itpemergencyvisitsarray[i].q46DateInvalidSoftError2 = false;
                itpemergencyvisitsarray[i].q46DateInvalidSoftError3 = false;

                if (itpemergencyvisitsarray[i].q46DateEmpty && itpemergencyvisitsarray[i].q46UnkEmpty)
                    hasError = true;
            }
            setitpemergencyvisitsDetails(itpemergencyvisitsarray);
        }

        let itpoutpatientvisitsarray = itpoutpatientvisitsDetails;
        if (hruDetails.hru_op_use === "1" || hruDetails.hru_op_use === 1) {
            for (let i = 0; i < itpoutpatientvisitsDetails.length; i++) {
                debugger;
                itpoutpatientvisitsarray[i].q47DateEmpty = itpoutpatientvisitsDetails[i].op_n1_dt === "";
                itpoutpatientvisitsarray[i].q47UnkEmpty = itpoutpatientvisitsDetails[i].op_n2_dt_unk === "";
                itpoutpatientvisitsarray[i].q47DateInvalidSoftError1 = false;
                itpoutpatientvisitsarray[i].q47DateInvalidSoftError2 = false;
                itpoutpatientvisitsarray[i].q47DateInvalidSoftError3 = false;

                if (itpoutpatientvisitsarray[i].q47DateEmpty && itpoutpatientvisitsarray[i].q47UnkEmpty)
                    hasError = true;
            }
            setitpoutpatientvisitsDetails(itpoutpatientvisitsarray);
        }

        if (q45Empty || q46Empty || q47Empty || hasError)
            return true;
        else
            return false;
    }

    const onsaveclick = async (validateForm) => {
        try {
            debugger;
            ToggleLoader(true);
            let status = validateForm ? validate() : false;
            if (status === true) {
                debugger;
                //setitphospitalizationDetails((prev) => ({
                //    ...prev,
                //    q45aDateInvalidSoftError1: false,
                //    q45aDateInvalidSoftError2: false,
                //    q45aDateInvalidSoftError3: false,
                //    q45bDateInvalidSoftError1: false,
                //    q45bDateInvalidSoftError2: false,
                //}));
                //setitpemergencyvisitsDetails((prev) => ({
                //    ...prev,
                //    q46DateInvalidSoftError1: false,
                //    q46DateInvalidSoftError2: false,
                //    q46DateInvalidSoftError3: false,
                //}));
                //setitpoutpatientvisitsDetails((prev) => ({
                //    ...prev,
                //    q47DateInvalidSoftError1: false,
                //    q47DateInvalidSoftError2: false,
                //    q47DateInvalidSoftError3: false,
                //}));
                setShowTopErrMsg(true);
                ToggleLoader(false);
            }
            else {
                setShowTopErrMsg(false);
                debugger;
                let data = {
                    HealthcareDetailsModel: {
                        hruDetails: {
                            hruid: hruDetails.hruid,
                            hru_ip_use: hruDetails.hru_ip_use,
                            hru_er_use: hruDetails.hru_er_use,
                            hru_op_use: hruDetails.hru_op_use,
                        },
                        ItphospitalizationDetails: [],
                        ItpemergencyvisitsDetails: [],
                        ItpoutpatientvisitsDetails: [],
                        Patientid: GetLocalStorageData("patientId") ?? 0,
                        UserID: GetLoggedInUserID(),
                        IsValidated: validateForm
                    },
                };

                if (itphospitalizationDetails.length > 0) {
                    for (let i = 0; i < itphospitalizationDetails.length; i++) {
                        debugger;
                        let ItphospitalizationData = {
                            Itphospitalizationid: itphospitalizationDetails[i].Itphospitalizationid,
                            ip_admit_n1_dt: itphospitalizationDetails[i].ip_admit_n1_dt,
                            ip_admit_n1_dt_unk: itphospitalizationDetails[i].ip_admit_n1_dt_unk === "1",
                            ip_disc_n1_dt: itphospitalizationDetails[i].ip_disc_n1_dt,
                            ip_disc_n1_dt_unk: itphospitalizationDetails[i].ip_disc_n1_dt_unk === "1",
                        };
                        data.HealthcareDetailsModel.ItphospitalizationDetails.push(ItphospitalizationData);
                    }
                }

                if (itpemergencyvisitsDetails.length > 0) {
                    for (let i = 0; i < itpemergencyvisitsDetails.length; i++) {
                        debugger;
                        let ItpemergencyvisitsData = {
                            Itpemergencyvisitsid: itpemergencyvisitsDetails[i].Itpemergencyvisitsid,
                            er_n1_dt: itpemergencyvisitsDetails[i].er_n1_dt,
                            er_n2_dt_unk: itpemergencyvisitsDetails[i].er_n2_dt_unk === "1",
                        };
                        data.HealthcareDetailsModel.ItpemergencyvisitsDetails.push(ItpemergencyvisitsData);
                    }
                }

                if (itpoutpatientvisitsDetails.length > 0) {
                    for (let i = 0; i < itpoutpatientvisitsDetails.length; i++) {
                        debugger;
                        let itpoutpatientvisitsData = {
                            Itpoutpatientvisitsid: itpoutpatientvisitsDetails[i].Itpoutpatientvisitsid,
                            op_n1_dt: itpoutpatientvisitsDetails[i].op_n1_dt,
                            op_n2_dt_unk: itpoutpatientvisitsDetails[i].op_n2_dt_unk === "1",
                        };
                        data.HealthcareDetailsModel.ItpoutpatientvisitsDetails.push(itpoutpatientvisitsData);
                    }
                }

                let response = await HealthcareService.SaveHealthcareData(
                    data.HealthcareDetailsModel
                );
                debugger
                if (response?.status !== HTTPResponse.OK) {
                    if (response?.status === HTTPResponse.Unauthorized)
                        HandleSessionTimeout();
                    else
                        throw response.error;
                } else {
                    await GetHealthcareData();
                    ToggleLoader(false);
                    ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                }
                return true;
            }
            return false;
        } catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
        }
    };

    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate
                            paths={navigatePaths}
                            Save={onsaveclick}

                        />
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div className="row">
                    <SideBarMenu activePageId={SideMenuItems.HRU} />
                    <div className="col-md-9 col-pad-left">
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>FORM 2</h4>
                            </div>
                            {showTopErrMsg && (
                                <div className="clean-error ">
                                    <ErrorField
                                        show={showTopErrMsg}
                                        message={CommonError_Msgs.Hard_Empty}
                                    />
                                </div>
                            )}
                            <div className="survey-question-section">
                                <div className="survey-question-content">
                                    <div style={PageDesignConstants.PageDescriptionStyle}>
                                        <i>
                                            The following questions will ask about your patient’s groats-related health resource utilization (HRU) from <span style={{ color: PageDesignConstants.fontColor }}>{indexDateMinus3Months}</span> through {""}
                                            <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>. A visit is considered “groats-related” if the purpose of the visit was to discuss or address the patient’s groats condition, groats-related treatment, or groats-related complications.
                                        </i>
                                        <i></i>
                                    </div>
                                    <div className="question-answer">
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>32.</span>
                                                <span className="quest-text-pad">
                                                    Between <span style={{ color: PageDesignConstants.fontColor }}>{indexDateMinus3Months}</span> and {""}
                                                    <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>, did
                                                    the patient have any groats-related hospitalizations?
                                                </span>
                                            </div>
                                            <div className="double-dig-answer">
                                                <div className="">
                                                    <input type="radio"
                                                        id="hru_ip_use"
                                                        name='radioQ45'
                                                        value={1}
                                                        checked={
                                                            hruDetails.hru_ip_use === "1" ||
                                                            hruDetails.hru_ip_use === 1
                                                        }
                                                        onChange={HandleradioChange} />
                                                    <span className="radio-text-padding">Yes</span>
                                                </div>
                                                {showq45repeater &&
                                                    <div className="dependent-section">
                                                        <div className="question-weight">
                                                            <p>
                                                                Please enter all dates of groats-related hospitalizations between{" "}
                                                                <span style={{ color: PageDesignConstants.fontColor }}>{indexDateMinus3Months}</span> and {" "}
                                                                <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>.
                                                            </p>
                                                        </div>
                                                        <div className="" style={{ marginTop: '-12px' }}>
                                                            {
                                                                itphospitalizationDetails.length > 0 ? (itphospitalizationDetails.map((data, idx) => (
                                                                    <div className="loop-section-ul">
                                                                        <div className="loop-head">
                                                                            <span className="question-weight">Set {idx + 1}</span>
                                                                            {idx != 0 && <div className="delete-btn-div    ">
                                                                                <img
                                                                                    width="16px"
                                                                                    src="../Assets/images/Icon-material-delete.png"
                                                                                    alt=""
                                                                                    onClick={(e) => deleteitphospitalizationDetails(idx)}
                                                                                />
                                                                            </div>}
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">a.</span>
                                                                                <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">
                                                                                        Date of admission:{" "}
                                                                                    </span>
                                                                                    <DateControl
                                                                                        optionId={"ip_admit_n1_dt"}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        ctrlId={"ip_admit_n1_dt-" + idx}
                                                                                        defDate={data.defDateQ45Admit}
                                                                                        date={data.ip_admit_n1_dt} />
                                                                                    (If only month and year are known, please enter “15” for
                                                                                    the day)
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={itphospitalizationDetails[idx].q45aDateInvalidSoftError1}
                                                                                message={HRU_Msgs.Q45RepeaterADateInvalidSoft1}
                                                                            />
                                                                            <ErrorField
                                                                                show={itphospitalizationDetails[idx].q45aDateInvalidSoftError2}
                                                                                message={HRU_Msgs.Q45RepeaterADateInvalidSoft2}
                                                                            />
                                                                            <ErrorField
                                                                                show={itphospitalizationDetails[idx].q45aDateInvalidSoftError3}
                                                                                message={HRU_Msgs.Q45RepeaterADateInvalidSoft3}
                                                                            />
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input type="radio"

                                                                                    name={"ip_admit_n1_dt_unk-" + idx}
                                                                                    value={1}
                                                                                    id={idx}
                                                                                    checked={
                                                                                        data.ip_admit_n1_dt_unk === "1" ||
                                                                                        data.ip_admit_n1_dt_unk === 1
                                                                                    }
                                                                                    onChange={HandleQ45AdmitDatechangeunk}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Date of admission is unknown
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={itphospitalizationDetails[idx].q45aDateEmpty && itphospitalizationDetails[idx].q45aUnkEmpty}
                                                                                message={HRU_Msgs.Q45RepeaterA}
                                                                            />
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">b.</span>
                                                                                <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">
                                                                                        Date of discharge:{" "}
                                                                                    </span>
                                                                                    <DateControl
                                                                                        optionId={"ip_disc_n1_dt"}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        ctrlId={"ip_disc_n1_dt-" + idx}
                                                                                        defDate={data.defDateQ45Disc}
                                                                                        date={data.ip_disc_n1_dt} />
                                                                                    (If only month and year are known, please enter “15” for
                                                                                    the day)
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={itphospitalizationDetails[idx].q45bDateInvalidSoftError1}
                                                                                message={HRU_Msgs.Q45RepeaterBDateInvalidSoft1}
                                                                            />
                                                                            <ErrorField
                                                                                show={itphospitalizationDetails[idx].q45bDateInvalidSoftError2}
                                                                                message={HRU_Msgs.Q45RepeaterBDateInvalidSoft2}
                                                                            />
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input type="radio"
                                                                                    name={"ip_disc_n1_dt_unk-" + idx}
                                                                                    value={1}
                                                                                    id={idx}
                                                                                    checked={
                                                                                        data.ip_disc_n1_dt_unk === "1" ||
                                                                                        data.ip_disc_n1_dt_unk === 1
                                                                                    }
                                                                                    onChange={HandleQ45DiscDatechangeunk}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Date of discharge is unknown
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={itphospitalizationDetails[idx].q45bDateEmpty && itphospitalizationDetails[idx].q45bUnkEmpty}
                                                                                message={HRU_Msgs.Q45RepeaterB}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))) : (<></>)
                                                            }
                                                            <div className="sub-question-bot-sapce-ul mt-2">
                                                                <div className="answer-list-text" onClick={addItphospitalizationRow}>
                                                                    <a>
                                                                        <img width="18px" src="../Assets/images/plus.png" alt="" />
                                                                    </a>
                                                                    <a className="radio-text-padding">
                                                                        Click here to add another set of dates
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="">
                                                    <input type="radio"
                                                        id="hru_ip_use"
                                                        name='radioQ45'
                                                        value={0}
                                                        checked={
                                                            hruDetails.hru_ip_use === "0" ||
                                                            hruDetails.hru_ip_use === 0
                                                        }
                                                        onChange={HandleradioChange} />
                                                    <span className="radio-text-padding">No</span>
                                                </div>
                                                <div className="">
                                                    <input type="radio"
                                                        id="hru_ip_use"
                                                        name='radioQ45'
                                                        value={99}
                                                        checked={
                                                            hruDetails.hru_ip_use === "99" ||
                                                            hruDetails.hru_ip_use === 99
                                                        }
                                                        onChange={HandleradioChange} />
                                                    <span className="radio-text-padding">Don't know/Unsure</span>
                                                </div>
                                                <ErrorField
                                                    show={hruDetails.q45Empty}
                                                    message={HRU_Msgs.Q45}
                                                />
                                            </div>
                                        </div>
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>33.</span>
                                                <span className="quest-text-pad">
                                                    Between <span style={{ color: PageDesignConstants.fontColor }}>{indexDateMinus3Months}</span> and {""}
                                                    <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>, did
                                                    the patient have any groats-related emergency room (ER) visits?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <input type="radio"
                                                        id="hru_er_use"
                                                        name='radioQ46'
                                                        value={1}
                                                        checked={
                                                            hruDetails.hru_er_use === "1" ||
                                                            hruDetails.hru_er_use === 1
                                                        }
                                                        onChange={HandleradioChange} />
                                                    <span className="radio-text-padding">Yes</span>
                                                </div>
                                                {showq46repeater &&
                                                    <div className="dependent-section">
                                                        <div className="question-weight">
                                                            <p className="margin-bot">
                                                                Please enter all dates of groats-related emergency visits between{" "}
                                                                <span style={{ color: PageDesignConstants.fontColor }}>{indexDateMinus3Months}</span> and {""}
                                                                <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>.{" "}
                                                            </p>
                                                        </div>
                                                        <div className="">
                                                            {
                                                                itpemergencyvisitsDetails.length > 0 ? (itpemergencyvisitsDetails.map((data, idx) => (
                                                                    <div className="loop-section-ul">
                                                                        <div className="loop-head">
                                                                            <span className="question-weight">ER Visit {idx + 1}</span>
                                                                            {idx != 0 && <div className="delete-btn-div    ">
                                                                                <img
                                                                                    width="16px"
                                                                                    src="../Assets/images/Icon-material-delete.png"
                                                                                    alt=""
                                                                                    onClick={(e) => deleteitpemergencyvisitsDetails(idx)}
                                                                                />
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                                            <div className="answer-list-text">
                                                                                <span className="" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">Date of visit: </span>
                                                                                    <DateControl
                                                                                        optionId={"er_n1_dt"}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        ctrlId={"er_n1_dt-" + idx}
                                                                                        defDate={data.defDateQ46}
                                                                                        date={data.er_n1_dt} />
                                                                                    (If only month and year are known, please enter “15” for
                                                                                    the day)
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={itpemergencyvisitsDetails[idx].q46DateInvalidSoftError1}
                                                                                message={HRU_Msgs.Q46RepeaterADateInvalidSoft1}
                                                                            />
                                                                            <ErrorField
                                                                                show={itpemergencyvisitsDetails[idx].q46DateInvalidSoftError2}
                                                                                message={HRU_Msgs.Q46RepeaterADateInvalidSoft2}
                                                                            />
                                                                            <ErrorField
                                                                                show={itpemergencyvisitsDetails[idx].q46DateInvalidSoftError3}
                                                                                message={HRU_Msgs.Q46RepeaterADateInvalidSoft3}
                                                                            />
                                                                            <div className="answer-list-text">
                                                                                <input type="radio"
                                                                                    name={"er_n2_dt_unk-" + idx}
                                                                                    value={1}
                                                                                    id={idx}
                                                                                    checked={
                                                                                        data.er_n2_dt_unk === "1" ||
                                                                                        data.er_n2_dt_unk === 1
                                                                                    }
                                                                                    onChange={HandleQ46Datechangeunk}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Date of visit is unknown
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={itpemergencyvisitsDetails[idx].q46DateEmpty && itpemergencyvisitsDetails[idx].q46UnkEmpty}
                                                                                message={HRU_Msgs.Q46Repeater}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))) : (<></>)}
                                                            <div className="sub-question-bot-sapce-ul mt-2">
                                                                <div className="answer-list-text" onClick={additpemergencyvisitsDetailsRow}>
                                                                    <a>
                                                                        <img width="18px" src="../Assets/images/plus.png" alt="" />
                                                                    </a>
                                                                    <a className="radio-text-padding">
                                                                        Click here to add another ER visit
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="">
                                                    <input type="radio"
                                                        id="hru_er_use"
                                                        name='radioQ46'
                                                        value={0}
                                                        checked={
                                                            hruDetails.hru_er_use === "0" ||
                                                            hruDetails.hru_er_use === 0
                                                        }
                                                        onChange={HandleradioChange} />
                                                    <span className="radio-text-padding">No</span>
                                                </div>
                                                <div className="">
                                                    <input type="radio"
                                                        id="hru_er_use"
                                                        name='radioQ46'
                                                        value={99}
                                                        checked={
                                                            hruDetails.hru_er_use === "99" ||
                                                            hruDetails.hru_er_use === 99
                                                        }
                                                        onChange={HandleradioChange} />
                                                    <span className="radio-text-padding">Don't know/Unsure</span>
                                                </div>
                                                <ErrorField
                                                    show={hruDetails.q46Empty}
                                                    message={HRU_Msgs.Q46}
                                                />
                                            </div>
                                        </div>
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>34.</span>
                                                <span className="quest-text-pad">
                                                    Between <span style={{ color: PageDesignConstants.fontColor }}>{indexDateMinus3Months}</span> and {""}
                                                    <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>, did
                                                    the patient have any groats-related outpatient (OP) visits?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <input type="radio"
                                                        id="hru_op_use"
                                                        name='radioQ47'
                                                        value={1}
                                                        checked={
                                                            hruDetails.hru_op_use === "1" ||
                                                            hruDetails.hru_op_use === 1
                                                        }
                                                        onChange={HandleradioChange} />
                                                    <span className="radio-text-padding">Yes</span>
                                                </div>
                                                {showq47repeater &&
                                                    <div className="dependent-section">
                                                        <div className="question-weight">
                                                            <p className="margin-bot">
                                                                Please enter all dates of groats-related outpatient visits
                                                                between <span style={{ color: PageDesignConstants.fontColor }}>{indexDateMinus3Months}</span> and{" "}
                                                                <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>.{" "}
                                                            </p>
                                                        </div>
                                                        <div className="">
                                                            {
                                                                itpoutpatientvisitsDetails.length > 0 ? (itpoutpatientvisitsDetails.map((data, idx) => (
                                                                    <div className="loop-section-ul">
                                                                        <div className="loop-head">
                                                                            <span className="question-weight">OP Visit {idx + 1}</span>
                                                                            {idx != 0 && <div className="delete-btn-div ">
                                                                                <img
                                                                                    width="16px"
                                                                                    src="../Assets/images/Icon-material-delete.png"
                                                                                    alt=""
                                                                                    onClick={(e) => deleteitpoutpatientvisitsDetails(idx)}
                                                                                />
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                                            <div className="answer-list-text">
                                                                                <span className="" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">Date of visit: </span>
                                                                                    <DateControl
                                                                                        ctrlId={"op_n1_dt-" + idx}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        optionId={"op_n1_dt"}
                                                                                        defDate={data.defDateQ47}
                                                                                        date={data.op_n1_dt} />
                                                                                    (If only month and year are known, please enter “15” for
                                                                                    the day)
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={itpoutpatientvisitsDetails[idx].q47DateInvalidSoftError1}
                                                                                message={HRU_Msgs.Q47RepeaterADateInvalidSoft1}
                                                                            />
                                                                            <ErrorField
                                                                                show={itpoutpatientvisitsDetails[idx].q47DateInvalidSoftError2}
                                                                                message={HRU_Msgs.Q47RepeaterADateInvalidSoft2}
                                                                            />
                                                                            <ErrorField
                                                                                show={itpoutpatientvisitsDetails[idx].q47DateInvalidSoftError3}
                                                                                message={HRU_Msgs.Q47RepeaterADateInvalidSoft3}
                                                                            />
                                                                            <div className="answer-list-text">
                                                                                <input type="radio"
                                                                                    name={"op_n2_dt_unk-" + idx}
                                                                                    value={1}
                                                                                    id={idx}
                                                                                    checked={
                                                                                        data.op_n2_dt_unk === "1" ||
                                                                                        data.op_n2_dt_unk === 1
                                                                                    }
                                                                                    onChange={HandleQ47Datechangeunk}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Date of visit is unknown
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={itpoutpatientvisitsDetails[idx].q47DateEmpty && itpoutpatientvisitsDetails[idx].q47UnkEmpty}
                                                                                message={HRU_Msgs.Q47Repeater}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))) : (<></>)}
                                                            <div className="sub-question-bot-sapce-ul mt-2">
                                                                <div className="answer-list-text" onClick={additpoutpatientvisitsDetailsRow}>
                                                                    <a>
                                                                        <img width="18px" src="../Assets/images/plus.png" alt="" />
                                                                    </a>
                                                                    <a className="radio-text-padding">
                                                                        Click here to add another OP visit
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="">
                                                    <input type="radio"
                                                        id="hru_op_use"
                                                        name='radioQ47'
                                                        value={0}
                                                        checked={
                                                            hruDetails.hru_op_use === "0" ||
                                                            hruDetails.hru_op_use === 0
                                                        }
                                                        onChange={HandleradioChange} />
                                                    <span className="radio-text-padding">No</span>
                                                </div>
                                                <div className="">
                                                    <input type="radio"
                                                        id="hru_op_use"
                                                        name='radioQ47'
                                                        value={99}
                                                        checked={
                                                            hruDetails.hru_op_use === "99" ||
                                                            hruDetails.hru_op_use === 99
                                                        }
                                                        onChange={HandleradioChange} />
                                                    <span className="radio-text-padding">Don't know/Unsure</span>
                                                </div>
                                                <ErrorField
                                                    show={hruDetails.q47Empty}
                                                    message={HRU_Msgs.Q47}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={onsaveclick} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Healthcare;
