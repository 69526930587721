export const ToastTimeout = 4000;
export const HTTPResponse = {
  OK: 200,
  BadRequest: 400,
  Unauthorized: 401,
  NotFound: 404,
  NotAllowed: 405,
  Conflict: 409,
  InternalServerError: 500,
};
export const AppContextIntialState = {
  isLoading: false,
  showToast: false,
  toastMessage: "",
  actionPopUp: {
    isOpen: false,
    title: "Alert",
    description: "Are you sure?",
    confirmButtonLabel: "Yes",
    disableCancelBtn: false,
  },
  toastType: undefined,
  pageDataChanged: false,

  showTopMenu: false,
  activeTab: undefined,

  showPatientDetails: false,
  showSideBar: false,

  lookId: {
    submitValue: "",
    formValue: "",
  },
  //abstractorPopUp: {
  //  isOpen: false,
  //  pageAction: undefined,
  //},
  // patient: {
  //   locked: false,
  // }
};
export const ToastMessageType = {
  Success: 0,
  Failed: 1,
};

export const TopMenuItems = {
  Patients: 1,
  Centers: 2,
  Abstractors: 3,
  Logout: 4,
};
export const SideMenuItems = {
  Introduction: 1,
  PatientScreening: 2,
  Demographics: 3,
  Questionnaire: 4, //tx on ava
  HRU: 5,
  ValidateSubmit: 6,
};
export const SideMenuListItem = {
  id: 0,
  title: "",
  path: "",
  look: "",
  active: false,
  enabled: false,
};

export const SideMenuPageList = [
  { id: 1, title: "INTRODUCTION", path: "/Introduction" },
  { id: 2, title: "PATIENT SCREENING", path: "/PatientScreening" },
  { id: 3, title: "DEMOGRAPHICS", path: "/Demographics", disabled: false },
  { id: 4, title: "FORM 1", path: "/Questionnaire", disabled: false },
  {
    id: 5,
    title: "FORM 2",
    path: "/Healthcare",
    disabled: false
  },
  { id: 6, title: "VALIDATE & SUBMIT", path: "/ValidateSubmit", disabled: false }
];
//Rpt with dosage, frequency, date
export const Rpt1Item = {
  dose: "",
  freq: "",
  date: "",
};

export const ToastMessages = {
  Save_Success: "Data has been Successfully saved.",
  Fail: "Oops!, Something went wrong.",
};

export const PageAction = {
  add: 1,
  edit: 2,
};

export const DefaultAGGridProps = {
  ROWS_PER_PAGE: 10,
  ROW_HEIGHT: 50,
  TABLE_HEIGHT: 50 * 10 + 100,
  NOT_FOUND: "No Records Found",
};

export const TableConstants = {
  PAGE_SIZE: 7,
  NOT_FOUND: "No Records Found",
};
export const PageDesignConstants = {
  backgroundColor: "#dff3f6",
  fontColor: "steelblue",
  fontWeight: "bold",
  PageDescriptionStyle: {
    fontWeight: "500",
    paddingBottom: "10px",
    textDecoration: "underline",
  },
};
export const DatePickerDefaultProps = {
  showMonthDropdown: true,
  showYearDropdown: true,
  dropdownMode: "select",
  placeholderText: "MM/DD/YYYY",
  dateFormat: "MM/dd/yyyy",
  //isClearable
  //closeOnScroll: true,
  //peekNextMonth:
};
export const ErrorMessages = {
  SAVE_ERROR: "Error occurred while saving.",
  LOAD_ERROR: "Error occurred while loading.",
  EMIAL_NONEXISTENT: "Email address does not exists.",
};
export const States = {
  Alabama: 1,
  Alaska: 2,
  Arizona: 3,
  Arkansas: 4,
  California: 5,
  Colorado: 6,
  Connecticut: 7,
  Delaware: 8,
  Florida: 9,
  Georgia: 10,
  Hawaii: 11,
  Idaho: 12,
  Illinois: 13,
  Indiana: 14,
  Iowa: 15,
  Kansas: 16,
  Kentucky: 17,
  Louisiana: 18,
  Maine: 19,
  Maryland: 20,
  Massachusetts: 21,
  Michigan: 22,
  Minnesota: 23,
  Mississippi: 24,
  Missouri: 25,
  Montana: 26,
  Nebraska: 27,
  Nevada: 28,
  New_Hampshire: 29,
  New_Jersey: 30,
  New_Mexico: 31,
  New_York: 32,
  North_Carolina: 33,
  North_Dakota: 34,
  Ohio: 35,
  Oklahoma: 36,
  Oregon: 37,
  Pennsylvania: 38,
  Rhode_Island: 39,
  South_Carolina: 40,
  South_Dakota: 41,
  Tennessee: 42,
  Texas: 43,
  Utah: 44,
  Vermont: 45,
  Virginia: 46,
  Washington: 47,
  West_Virginia: 48,
  Wisconsin: 49,
  Wyoming: 50,
  Washington_DC: 50,
};
export const WeightUnit = {
  1: "lbs",
  2: "kgs"
}
export const Role = {
  Admin: 1, Center: 2
}
//export const FormStatus = {
//  ACCECPT: 1,
//  REJECT: 2,
//  UNSAVED: 3
//}