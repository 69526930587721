import React from "react";

const Header = () => {
  return (
    <section className="header-section">
      <div className="container">
        <h5>     
            Center Based Chart Review for Groats Patients
        </h5>
        <h4>PATIENT CASE REPORT FORM (CRF)</h4>
      </div>
    </section>
  );
};

export default Header;
