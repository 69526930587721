import React, { useContext, useEffect, useRef, useState } from "react";
import { AddLocalStorageData, GetLocalStorageData, GetLoggedInUserID, GetRandomAlphaNumber } from "../../Helpers/Utilities";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import { HTTPResponse, PageDesignConstants, SideMenuItems, ToastMessageType, ToastMessages } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import { AppContext } from "../../Contexts/AppContextProvider";
import { CommonError_Msgs } from "../../Helpers/HelperText";
import { PatientService } from "../../WebApiServices/Patient.service";

const Introduction = () => {

  //let patientID = GetLocalStorageData("patientId") ?? 0;
  //const uniqueEncryptedPatientId = GetLocalStorageData("uniqueEncryptedPatientId") ?? 0;
  const userId = GetLoggedInUserID() ?? 0;
  //const surveyDate = GetLocalStorageData("surveyDate") ?? 0;
  let patientData = GetLocalStorageData("patient")
  patientData = patientData && JSON.parse(patientData);

  const patientID = patientData?.patientId ?? 0;
  const centerId = patientData?.ptCenterId ?? 0;
  const abstractorId = patientData?.abstractorId ?? 0;
  const uniqueEncryptedPatientId = patientData?.uniqueEncryptedPatientId ?? 0;
  const surveyDate = patientData?.surveyDate ?? "";

  const { ShowToast, ToggleLoader, HandleSessionTimeout } = useContext(AppContext)
  const navigatePaths = {
    currPage: "/Introduction",
    nextPage: "/PatientScreening",
  };
  const [randomAlphabet, setRandomAlphabet] = useState();

  const SaveForm = async () => {
    try {
      if (patientID === 0)
        return await AddNewPatient();
      else {
        return true;
      }
    } catch {

    }
  }
  /* <summary>
date: 24-10-2023
Name: AP
description: Save New Patient 
<summary>*/
  const AddNewPatient = async () => {
    try {

      const payload = {
        AbstractorId: userId
      }
      ToggleLoader(true)
      const response = await PatientService.AddNewPatient(payload);
      ToggleLoader(false)
      if (response?.status === HTTPResponse.OK) {
        // setDetails({
        //   patientId: response?.data?.patientId,
        //   uniqueEncryptedPatientId: response?.data?.uniqueEncryptedPatientId,
        //   abstractionDate: response?.data?.abstractionDate
        // })
        AddLocalStorageData("patientId", response?.data?.patientId)
        //AddLocalStorageData("uniqueEncryptedPatientId", response?.data?.uniqueEncryptedPatientId);
        //AddLocalStorageData("surveyDate", response?.data?.abstractionDate);



        const ptArr = {
          patientId: response?.data?.patientId ?? 0,
          uniqueEncryptedPatientId: response?.data?.uniqueEncryptedPatientId ?? 0,
          surveyDate: response?.data?.abstractionDate ?? "",
          locked: false,
          ptCenterId: response?.data?.siteId ?? 0,
          abstractorId: response?.data?.abstractorId ?? 0
        };
        AddLocalStorageData("patient", JSON.stringify(ptArr))

        ShowToast("Patient has been saved successfully", ToastMessageType.Success);
        return true;
      } else {
        if(response?.status === HTTPResponse.Unauthorized){
          HandleSessionTimeout();
        }else{
          throw response.error;
        }
      }
    } catch {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  }
  /* <summary>
  date: 21-11-2023
  Name: AP
  description: Generate random alphabet
  <summary>*/
  useEffect(() => {
    GenerateRandomAlphabet()
  }, [])
  const GenerateRandomAlphabet = () => {
    setRandomAlphabet(GetRandomAlphaNumber())
  }

  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SaveForm} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.Introduction} />
          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>INTRODUCTION</h4>
              </div>
              <div className="survey-introduction-section">
                <div className="survey-introduction-content">
                  <div className="instructions">
                    <h5>INSTRUCTIONS</h5>
                    {/* <div class="note-list">
                              <span class="instructon-point-spacing font-style-spacing">[Assign a unique abstractor ID to respondent and unique Center ID code to each specific site]</span>
                          </div> */}
                    <div>
                      <ul>
                        <li className="font-style-spacing">
                          Patient ID # (Scrambled unique identifier):{" "}
                          <label className="font-style-value" htmlFor="">
                            {uniqueEncryptedPatientId !== 0 && uniqueEncryptedPatientId}
                          </label>
                        </li>
                        <li className="font-style-spacing">
                          Site / Center ID # (Center Identification Number):{" "}
                          <label className="font-style-value" htmlFor="">
                            {centerId !== 0 && centerId}
                          </label>
                        </li>
                        <li className="font-style-spacing">
                          Abstractor ID (Numeric identity of abstractor):{" "}
                          <label className="font-style-value" htmlFor="">
                            {abstractorId !== 0 && abstractorId}
                          </label>
                        </li>
                        <li className="font-style-spacing">
                          Date of Chart Data Abstraction Initiation:{" "}
                          <label className="font-style-value" htmlFor="">
                            {surveyDate !== 0 && surveyDate}

                          </label>
                        </li>
                      </ul>
                    </div>
                    <div className="">
                      <span>
                        The assigned unique patient identifier for this patient
                        is {" "}
                        {uniqueEncryptedPatientId !== 0 && (
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                            {uniqueEncryptedPatientId}
                          </span>
                        )}
                        . Please make a
                        note of the unique patient identifier for your records.
                        Unique patient identifiers will be used to help you keep
                        track of the charts you have abstracted for this study
                      </span>
                    </div>
                  </div>
                    <div className="note-border-box">
                                      <div className="important-note"                                      >
                      <h6>List of inclusion criteria:</h6>
                      <div>
                        <ul>
                          <li>
                            Diagnosed with groats (chronic or episodic)
                          </li>
                          <li>
                            Received Treatment X injections (at least 2 cycles) for a minimum of 6 consecutive months, followed by Treatment X discontinuation*
                            <ul>
                                <li>*Note: Treatment X discontinuation is defined as either stopping Treatment X entirely or missing at least one Treatment X cycle</li>
                            </ul>
                                                  </li>
                                                  <li>
                                                      Treated with treatment X, meeting one of the following treatment sequences:
                                                      <ul>
                                                        <li>
                                                              Monotherapy cohort:
                                                              <ol>
                                                                  <li>Received Treatment X without treatment X
                                                                  </li>
                                                                  <li>Discontinued Treatment X treatment (i.e., 100 days without Treatment X after the last injections)
                                                                  </li>
                                                                  <li>Initiated treatment X within 2 weeks, and continued treatment X (without any concomitant Treatment X treatment) for at least 3 consecutive months
                                                                  </li>
                                                              </ol>
                                                          </li>
                                                          <li>
                                                              Combination therapy cohort:
                                                              <ol>
                                                                  <li>Received Treatment X and treatment X concomitantly
                                                                  </li>
                                                                  <li>Discontinued Treatment X treatment, but continued treatment X (without any concomitant Treatment X treatment) for at least 3 consecutive months
                                                                  </li>
                                                              </ol>
                                                          </li>
                                                      </ul>
                                                  </li>
                                                  <li>
                                                      Adult patient aged at least 18 years old as of treatment X initiation (for monotherapy cohort) or Treatment X discontinuation (for combination therapy cohort)
                                                  </li>
                                                  <li>
                                                      Disease, treatment and healthcare use history available for the 6 months before and 3+ months after treatment X initiation (for monotherapy cohort) or Treatment X discontinuation (for combination therapy cohort)
                                                  </li>
                        </ul>
                                          </div><br/>
                                          <h6>
                                              List of exclusion criteria:</h6>
                                          <div>
                                              <ul>
                                                  <li>
Treated with treatment X or another anti-calcitonin gene-related peptide (anti-CGRP) agent in a clinical trial                                                  </li>                                              </ul>
                                          </div>
                    </div>
                </div>
                    <br />
                    <div className="note-border-box">

                                          <div>
                                              <span>Please note that the data collection form will not collect any Protected Health Information (PHI). Because dates of events can be considered as PHI, at the time of data entry, a de-identification technique will be applied so that all dates will be anonymized. Through data collection, respondents will be asked to enter the date of a specific event as it appears in the patient’s chart. However, the specific date will not be stored. Instead, all dates will be reset and only stored as the number of days or months relative to a reference date; here, the date of treatment X initiation (for monotherapy cohort) or Treatment X discontinuation (for combination therapy cohort) will be used as the reference date (Day 0).
                                              </span>
                                          </div>
                      
                  </div>
                              </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SaveForm} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Introduction;
