import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import SideBarMenu from "../../Components/SideBarMenu";
import { useNavigate } from "react-router-dom";
import {
    HTTPResponse,
    PageDesignConstants,
    Rpt1Item,
    SideMenuItems,
    SideMenuPageList,
    ToastMessageType,
    ToastMessages,
    WeightUnit,
} from "../../Helpers/Enums";
import DateControl from "../../Components/DateControl";
import { AddLocalStorageData, AddMonths, AllowOnlyIntegers, AllowPositiveDecimalNumbers, DisplayFormat, FormatDate, GetLocalStorageData, GetLoggedInUserID, IsDateAfterToday, SubtractMonths, isStringEmpty } from "../../Helpers/Utilities";
import ErrorField from "../../Components/ErrorField";
import { CommonError_Msgs, PatientScreening_Msgs } from "../../Helpers/HelperText";
import { PatientService } from "../../WebApiServices/Patient.service";
import { AppContext } from "../../Contexts/AppContextProvider";

const PatientScreening = () => {
    const [isEligible, setIsEligible] = useState(false);
    const [isAllDataFilled, setIsAllDataFilled] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const pid = GetLocalStorageData("patientId") ?? 0;
    const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout } = useContext(AppContext);

    const [ptDetails, setPtDetails] = useState({
        metInclusionCriteria: null,
        yob: "",
        q2PrimaryITPDiagnosisSelect: "",
        q2Diagnosis_dt: "",
        q2Diagnosis_month_yr: "MM/15/YYYY",
        q2Diagnosis_yr: "",
        q3TmtInitiationDtSelect: "",
        q3Index_date: "",
        q3Index_month_yr: "MM/15/YYYY",
        q4Full_med_records_pre_avaSelect: "",
        q5Pc_record_pre_indexSelect: "",
        q6Ava_trial_participationSelect: "",
        q7Full_med_records_post_avaSelect: "",
        q8Last_record_date: "",
        q9Pt_still_aliveSelect: "",
        q10Death_6mo_post_avaSelect: "",
        q11Year_of_death: "",
        q11Year_of_deathRadio: "",
        q11Death_year_unk: "",
        q12Pt_still_at_clinicSelect: "",
        q13VisibleBasedonQ8: false,
        q13Pt_left_clinic_reasonSelect: "",
        q13Pt_left_clinic_reason_oth_txt: "",
        q14Comorb_cpd: "",
        q14Comorb_diabetes: "",
        q14Comorb_renal_failure: "",
        q14Comorb_vasc_events: "",
        q14Comorb_hep_c: "",
        q14Comorb_hiv: "",
        q14Comorb_any_malig: "",
        q14Comorb_cld: "",
        q14Comorb_none: "",
        q14Comorb_hema_oth: "",
        q14Comorb_hema_oth_txt: "",
        q15Pt_weight: "",
        q15Pt_weight_unitSelect: 0,//initialising to Please Select
        q15Pt_weight_unk: false,
        q3Index_date_old_val: "",//sifter 11082 state to hold saved index date
        q8Last_record_date_old_val: ""//sifter 11082 state to hold saved last record date

    });
    const [index_dt, setIndexDt] = useState()
    const [index_dt_minus_1_day, setIndexDateMinus1Day] = useState()
    const [index_dt_minus_3mo, setIndexDateMinus3Months] = useState()
    const [index_dt_plus_6mo, setIndexDatePlus6Months] = useState()

    const navigatePaths = {
        prevPage: "/Introduction",
        currPage: "/PatientScreening",
        nextPage: isLocked || (isAllDataFilled && isEligible) ? "/Demographics" : (isAllDataFilled && !isEligible) ? "/ManagePatients" : "/PatientScreening",
    };


    const [showTopErrMsg, setShowTopErrMsg] = useState(false)

    const InitialSoftErrList = {
        q2Terminate: false,
        q3_indexDt_before: false,
        q3_indexDt_after: false,
        q3_indexDt_unk: false,
        q3_pt_no_ava: false,
        q3_below18: false,
        q3Terminate: false,
        q4Terminate: false,
        q5Terminate: false,
        q6Terminate: false,
        q7Full_med_records_post_avaSelect: false,

        //q8LessThanIndex6mo: false,
        q9Terminate: false,
        q10Terminate: false,
        q15WeightCheck: false,
    }
    const [softErrList, setSoftErrList] = useState(InitialSoftErrList)

    const InitialHardErrList = {
        q1Empty: false,
        q1InvalidYear: false,
        q2Empty: false,
        q2LessThanYob: false,//left
        q3Empty: false,
        q3lessThanDiagnosisDt: false,
        q4Empty: false,
        q5Empty: false,
        q6Empty: false,
        q7Empty: false,
        q8Empty: false,
        //q8AfterDeath: false,//left
        q9Empty: false,
        q10Empty: false,
        q11Empty: false,
        q11LessThanIndexDt: false,//leftq11
        q11LessThanIndexYr: false,//left
        q12Empty: false,
        q13Empty: false,
        q14Empty: false,
        q14EmptyOtherTxt: false,
        q15Empty: false,
        q15EmptyUnit: false,

        q2AfterToday: false,
        q3AfterToday: false,
        q8AfterToday: false,
        q8Beforeq3Date: false,
    }
    const [hardErrList, setHardErrList] = useState(InitialHardErrList)

    const [showBasedOnQ3Response, setShowBasedOnQ3Response] = useState(false);

    const [showQ9, setShowQ9] = useState();

    const [defYearQ11, setDefyearQ11] = useState();
    const [defDateQ2, setDefDateQ2] = useState();
    const [defYearQ2, setDefYearQ2] = useState();
    const [defYearMonthQ2, setDefYearMonthQ2] = useState();
    const [defDateQ3, setDefDateQ3] = useState();
    const [defYearMonthQ3, setDefYearMonthQ3] = useState();
    const {
        yob,
        q8Last_record_date,
        q2PrimaryITPDiagnosisSelect,
        q3TmtInitiationDtSelect,
        q3Index_date, q3Index_month_yr,
        q4Full_med_records_pre_avaSelect,
        q5Pc_record_pre_indexSelect,
        q6Ava_trial_participationSelect,
        q7Full_med_records_post_avaSelect,
        q9Pt_still_aliveSelect,
        q10Death_6mo_post_avaSelect,
        q11Death_year_unk,
    } = ptDetails;

    let index_date = q3TmtInitiationDtSelect === 1 ? new Date(q3Index_date)
        : q3TmtInitiationDtSelect === 2 ? new Date(q3Index_month_yr)
            : "";


    /* <summary>
    date: 13-10-2023
    Name: AP
    description: Effect runs when q3 or q8 date changes to show/hide q9 
    <summary>*/
    useEffect(() => {

        const q8Dt = new Date(q8Last_record_date);
        let q3Dt = new Date(ptDetails.q3TmtInitiationDtSelect === 1 ? ptDetails.q3Index_date : ptDetails.q3TmtInitiationDtSelect === 2 ? ptDetails.q3Index_month_yr : "");

        //let q3Dt = new Date(q3Index_date !== "" ? q3Index_date : q3Index_month_yr !== "" ? q3Index_month_yr : "");

        const q8_minus6moTemp = SubtractMonths(q8Dt, 6)
        const q8_minus6mo = q8_minus6moTemp.setDate(q8_minus6moTemp.getDate() + 1);

        if (!isNaN(q3Dt) && !isNaN(q8Dt) && q8_minus6mo < q3Dt) {
            // (q8Dt.getFullYear() - q3Dt.getFullYear()) * 12 +
            // (q8Dt.getMonth() - q3Dt.getMonth()) <
            // 6
            setShowQ9(true);
            // setSoftErrList((prev) => ({
            //   ...prev,
            //   q8LessThanIndex6mo: true,
            // }));
        }
        else {
            setShowQ9(false);
            setPtDetails((prev) => ({
                ...prev,
                q9Pt_still_aliveSelect: "",

            }));
            // setSoftErrList((prev) => ({
            //   ...prev,
            //   q8LessThanIndex6mo: false,
            // }));
        }

        const q3DtCopy = new Date(q3Dt);
        const index_date = isNaN(q3Dt) ? "[index date]" : DisplayFormat(q3Dt);
        let indexMinus1Day = new Date(q3Dt);
        indexMinus1Day.setDate(indexMinus1Day.getDate() - 1);
        const iMinus1Day = isNaN(q3Dt) ? "[index_date - 1 day]" : DisplayFormat(indexMinus1Day)

        const iMinus3Mo = SubtractMonths(q3Dt, 3)
        const indexMinus3Mo = isNaN(q3Dt) ? "[index_date - 3 months]" : DisplayFormat(iMinus3Mo);



        const iPlus6Mo = AddMonths(q3DtCopy, 6)
        const indexPlus6Mo = isNaN(q3DtCopy) ? "[index_date + 6 months]" : DisplayFormat(iPlus6Mo);
        //const indexPlus6Mo = 

        setIndexDt(index_date)
        setIndexDateMinus1Day(iMinus1Day)
        setIndexDateMinus3Months(indexMinus3Mo)
        setIndexDatePlus6Months(indexPlus6Mo)

    }, [q3Index_date, q3Index_month_yr, q8Last_record_date]);



    // useEffect(() => {
    //   if (q11Death_year_unk) {
    //     setPtDetails((prev) => ({
    //       ...prev,
    //       q11Year_of_death: "",
    //     }));
    //   }
    // }, [q11Death_year_unk]);

    //Q3 radios

    const functionQ3DateSoftError = (indexDate, mothOrYearSelect) => {
        const date1 = new Date("2019/07/01");
        const date2 = new Date("2023/03/31");


        let q3Dt = null;
        let dtq3Prev = ptDetails.q3Index_month_yr;//MM/15/YYYY

        if (mothOrYearSelect === 0) {//full date chosen
            q3Dt = indexDate !== "" ? new Date(indexDate) : null;
        }
        else {//partial date chosen(either month or year)
            if (mothOrYearSelect === 1) {//month sel
                const monthtemp = dtq3Prev.replace(dtq3Prev.split('/')[0], indexDate);
                q3Dt = monthtemp !== "" ? new Date(monthtemp) : null;

            } else if (mothOrYearSelect === 2) {//year sel
                const yrTemp = dtq3Prev.replace(dtq3Prev.split('/').pop(), indexDate);
                q3Dt = yrTemp !== "" ? new Date(yrTemp) : null
            }
        }
        setSoftErrList((prev) => ({
            ...prev,
            q3_indexDt_before: false,
            q3_indexDt_after: false,
            q3_below18: false,
            q3_indexDt_unk: false,
            q3_pt_no_ava: false
        }))


        if (q3Dt !== "" && !isNaN(q3Dt) && q3Dt != null) {
            if (q3Dt < date1) {
                setSoftErrList((prev) => ({
                    ...prev,
                    q3_indexDt_before: true,
                    //q3_indexDt_unk: false,
                    //q3_pt_no_ava: false
                }));
            }
            else {
                setSoftErrList((prev) => ({
                    ...prev,
                    //q3_indexDt_before: false,
                    //q3_indexDt_unk: false,
                    //q3_pt_no_ava: false
                }));
            }
            if (q3Dt > date2) {
                setSoftErrList((prev) => ({
                    ...prev,
                    q3_indexDt_after: true,
                    //q3_indexDt_unk: false,
                    //q3_pt_no_ava: false
                }));
            }
            else {
                setSoftErrList((prev) => ({
                    ...prev,
                    //q3_indexDt_after: false,
                    //q3_indexDt_unk: false,
                    // q3_pt_no_ava: false
                }));
            }

            if (Number(ptDetails.yob) !== 0 && !isNaN(q3Dt) && (Number((q3Dt).getFullYear()) - Number(ptDetails.yob) < 18)) {
                setSoftErrList((prev) => ({
                    ...prev,
                    q3_below18: true,
                    // q3_indexDt_unk: false,
                    // q3_pt_no_ava: false
                }));
            }
            else {
                setSoftErrList((prev) => ({
                    ...prev,
                    //q3_below18: false,
                    //q3_indexDt_unk: false,
                    //q3_pt_no_ava: false
                }));
            }
        }
    }

    // useEffect(() => {
    //   if (!isStringEmpty(q15Pt_weight) && q15Pt_weight_unitSelect !== 0 &&
    //     ((q15Pt_weight_unitSelect === 1 && (Number(q15Pt_weight) <= 12 || Number(q15Pt_weight) >= 900))//lbs
    //       || (q15Pt_weight_unitSelect === 2 && (Number(q15Pt_weight) <= 5 || Number(q15Pt_weight) >= 408)))//kgs      
    //   )
    //     setSoftErrList((prev) => ({
    //       ...prev,
    //       q15: true,
    //     }));
    //   else
    //     setSoftErrList((prev) => ({
    //       ...prev,
    //       q15: false,
    //     }));
    // }, [q15Pt_weight])
    // useEffect(() => {
    //   if (ptDetails.q14Comorb_hema_oth === false) {
    //     setPtDetails((prev) => ({
    //       ...prev,
    //       q14Comorb_hema_oth_txt: ""
    //     })
    //     )
    //   }

    // }, ptDetails.q14Comorb_hema_oth)

    // }, ptDetails.q14Comorb_hema_oth)
    /* <summary>
    date: 13-10-2023
    Name: AP
    description: onChange event handler basis name of the field
    <summary>*/
    const HandleFieldChange = (e) => {
        const { name, id, value } = e.target;

        switch (name) {
            case "q2PrimaryITPDiagnosisSelect":
            case "q3TmtInitiationDtSelect":
            case "q4Full_med_records_pre_avaSelect":
            case "q5Pc_record_pre_indexSelect":
            case "q6Ava_trial_participationSelect":
            case "q9Pt_still_aliveSelect":
            case "q10Death_6mo_post_avaSelect":
            case "q12Pt_still_at_clinicSelect":
            case "q13Pt_left_clinic_reasonSelect": {


                setPtDetails((prev) => ({
                    ...prev,
                    [name]: parseInt(value),
                }));
                if (name === "q2PrimaryITPDiagnosisSelect") {
                    setHardErrList((prev) => ({
                        ...prev,
                        q2Empty: false,
                        q2LessThanYob: false,
                        q2AfterToday: false
                    }))
                    setSoftErrList((prev) => ({
                        ...prev,
                        q2Terminate: false
                    }));
                    switch (Number(id.split('_')[1])) {
                        case 1: {
                            setPtDetails((prev) => ({
                                ...prev,
                                q2Diagnosis_month_yr: "",
                                q2Diagnosis_yr: ""
                            }));
                            setDefDateQ2(false)
                            setDefYearQ2(true)
                            setDefYearMonthQ2(true)
                            setPtDetails((prev) => ({
                                ...prev,
                                q2Diagnosis_yr: "",
                                q2Diagnosis_month_yr: "MM/15/YYYY"
                            }));
                            setIsEligible(true);
                            break;
                        } case 2: {
                            setPtDetails((prev) => ({
                                ...prev,
                                q2Diagnosis_dt: "",
                                q2Diagnosis_yr: ""
                            }));
                            setDefDateQ2(true)
                            setDefYearQ2(true)
                            setDefYearMonthQ2(false)
                            setPtDetails((prev) => ({
                                ...prev,
                                q2Diagnosis_dt: "",
                                q2Diagnosis_yr: "",
                            }));
                            setIsEligible(true);
                            break;
                        } case 3: {
                            setPtDetails((prev) => ({
                                ...prev,
                                q2Diagnosis_dt: "",
                                q2Diagnosis_month_yr: ""
                            }));
                            setDefDateQ2(true)
                            setDefYearQ2(false)
                            setDefYearMonthQ2(true)
                            setPtDetails((prev) => ({
                                ...prev,
                                q2Diagnosis_dt: "",
                                q2Diagnosis_month_yr: "MM/15/YYYY",
                            }));
                            setIsEligible(true);
                            break;
                        }
                        case 4:
                            {
                                setIsEligible(true);
                                setDefDateQ2(true)
                                setDefYearQ2(true)
                                setDefYearMonthQ2(true)
                                setPtDetails((prev) => ({
                                    ...prev,
                                    q2Diagnosis_dt: "",
                                    q2Diagnosis_yr: "",
                                    q2Diagnosis_month_yr: "MM/15/YYYY",
                                }));
                                break;
                            }
                        case 5: {
                            setIsEligible(false);
                            setDefDateQ2(true)
                            setDefYearQ2(true)
                            setDefYearMonthQ2(true)
                            setPtDetails((prev) => ({
                                ...prev,
                                q2Diagnosis_dt: "",
                                q2Diagnosis_yr: "",
                                q2Diagnosis_month_yr: "MM/15/YYYY",
                            }));
                            if (Number(id.split('_')[1]) === 5) {// "5"PatientScreening_Msgs.INELIGIBLE               
                                setSoftErrList((prev) => ({
                                    ...prev,
                                    q2Terminate: true,
                                }));
                            }
                            break;
                        }
                        default:
                            break
                    }
                    // if (name === "q9Pt_still_aliveSelect") {
                    //   setPtDetails((prev) => ({
                    //     ...prev,
                    //     q10Death_6mo_post_avaSelect: "",
                    //   }));
                    // }
                }
                else if (name === "q3TmtInitiationDtSelect") {

                    setHardErrList((prev) => ({
                        ...prev,
                        q3Empty: false, q11LessThanIndexYr: false, q3lessThanDiagnosisDt: false, q3AfterToday: false
                    }))
                    switch (Number(id.split('_').pop())) {//id.split('_').pop()
                        case 1: {//"1"
                            setDefDateQ3(false)
                            setDefYearMonthQ3(true)
                            setPtDetails((prev) => ({
                                ...prev,
                                q3Index_month_yr: "MM/15/YYYY"
                            }));
                            setSoftErrList((prev) => ({
                                ...prev,
                                q3_indexDt_before: false,
                                q3_indexDt_after: false,
                                q3_below18: false,

                                q3_indexDt_unk: false,
                                q3_pt_no_ava: false,
                            }));
                            setIsEligible(true);
                            break;
                        } case 2: {//"2"
                            setDefDateQ3(true)
                            setDefYearMonthQ3(false)
                            setPtDetails((prev) => ({
                                ...prev,
                                q3Index_date: ""
                            }));
                            setSoftErrList((prev) => ({
                                ...prev,
                                q3_indexDt_before: false,
                                q3_indexDt_after: false,
                                q3_below18: false,

                                q3_indexDt_unk: false,
                                q3_pt_no_ava: false
                            }));
                            setIsEligible(true);
                            break;
                        }
                        case 3: {
                            setDefDateQ3(true)
                            setDefYearMonthQ3(true)
                            setPtDetails((prev) => ({
                                ...prev,
                                q3Index_date: "",
                                q3Index_month_yr: "MM/15/YYYY"
                            }));
                            setSoftErrList((prev) => ({
                                ...prev,
                                q3_indexDt_before: false,
                                q3_indexDt_after: false,
                                q3_below18: false,
                                q3_indexDt_unk: true,
                                q3_pt_no_ava: false
                            }));
                            setIsEligible(false);
                            break;
                        }//"3"
                        case 4: {//"4":
                            setDefDateQ3(true)
                            setDefYearMonthQ3(true)
                            setPtDetails((prev) => ({
                                ...prev,
                                q3Index_date: "",
                                q3Index_month_yr: "MM/15/YYYY"
                            }));
                            setSoftErrList((prev) => ({
                                ...prev,
                                q3_indexDt_before: false,
                                q3_indexDt_after: false,
                                q3_below18: false,
                                q3_indexDt_unk: false,
                                q3_pt_no_ava: true
                            }));
                            setIsEligible(false);
                            break;
                        }
                        default:
                            break
                    }
                    setShowBasedOnQ3Response(false);
                }
                else if (name === "q4Full_med_records_pre_avaSelect") {

                    setHardErrList((prev) => ({
                        ...prev,
                        q4Empty: false
                    }))
                    if (value == 0 || value == 99) {
                        setSoftErrList((prev) => ({
                            ...prev,
                            q4Terminate: true
                        }));
                        setIsEligible(false);
                    }
                    else {
                        setSoftErrList((prev) => ({
                            ...prev,
                            q4Terminate: false
                        }))
                        setIsEligible(true);
                    }
                }
                else if (name === "q5Pc_record_pre_indexSelect") {
                    setHardErrList((prev) => ({
                        ...prev,
                        q5Empty: false
                    }))

                    if (value == 0 || value == 99) {
                        setSoftErrList((prev) => ({
                            ...prev,
                            q5Terminate: true
                        }))
                    } else {
                        setSoftErrList((prev) => ({
                            ...prev,
                            q5Terminate: false
                        }))
                    }
                }
                else if (name === "q6Ava_trial_participationSelect") {
                    setHardErrList((prev) => ({
                        ...prev,
                        q6Empty: false
                    }))

                    if (value == 1 || value == 99) {
                        setSoftErrList((prev) => ({
                            ...prev,
                            q6Terminate: true
                        }))
                        setIsEligible(false);
                    } else {
                        setSoftErrList((prev) => ({
                            ...prev,
                            q6Terminate: false
                        }))
                        setIsEligible(true);
                    }
                }
                else if (name === "q9Pt_still_aliveSelect") {
                    setPtDetails((prev) => ({
                        ...prev,
                        q10Death_6mo_post_avaSelect: "",
                        q11Year_of_deathRadio: false,
                        q11Year_of_death: "",
                        q11Death_year_unk: false,
                        q13Pt_left_clinic_reasonSelect: "",
                        q13Pt_left_clinic_reason_oth_txt: "",
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        q9Empty: false,
                    }))

                    if (value == 1 || value == 99) {
                        setSoftErrList((prev) => ({
                            ...prev,
                            q9Terminate: true,
                            q10Terminate: false
                        }));
                    }
                    else {
                        setSoftErrList((prev) => ({
                            ...prev,
                            q9Terminate: false
                        }));
                    }
                }
                else if (name === "q10Death_6mo_post_avaSelect") {
                    setPtDetails((prev) => ({
                        ...prev,
                        q11Year_of_deathRadio: false,
                        q11Year_of_death: "",
                        q11Death_year_unk: false,
                    }));

                    setHardErrList((prev) => ({
                        ...prev,
                        q10Empty: false,
                    }))

                    if (value == 0 || value == 99) {
                        setSoftErrList((prev) => ({
                            ...prev,
                            q10Terminate: true
                        }));
                        // setPtDetails((prev) => ({
                        //   ...prev,
                        //   q11Year_of_deathRadio: false,
                        //   q11Year_of_death: "",
                        //   q11Death_year_unk: false
                        // }))
                    }
                    else {
                        setSoftErrList((prev) => ({
                            ...prev,
                            q10Terminate: false
                        }));
                        // setPtDetails((prev) => ({
                        //   ...prev,
                        //   q11Year_of_deathRadio: false
                        // }))
                    }
                }
                else if (name === "q12Pt_still_at_clinicSelect") {
                    setHardErrList((prev) => ({
                        ...prev,
                        q12Empty: false,
                    }))
                    setPtDetails((prev) => ({
                        ...prev,
                        q13Pt_left_clinic_reasonSelect: "",
                        q13Pt_left_clinic_reason_oth_txt: "",
                    }))
                }
                else if (name === "q13Pt_left_clinic_reasonSelect") {
                    if (Number((id.split('_').pop())) !== 5)//"5"
                        setPtDetails((prev) => ({
                            ...prev,
                            q13Pt_left_clinic_reason_oth_txt: "",
                        }));
                    setHardErrList((prev) => ({
                        ...prev,
                        q13Empty: false,
                    }))
                }
                break;
            }

            case "q13Pt_left_clinic_reason_oth_txt": {
                setPtDetails((prev) => ({
                    ...prev,
                    q13Pt_left_clinic_reasonSelect: 5,
                    [name]: value,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q13Empty: false,
                }))
                break;
            }
            case "q7Full_med_records_post_avaSelect": {
                if (value == 0) {
                    setSoftErrList((prev) => ({
                        ...prev,
                        q7Full_med_records_post_avaSelect: true,
                    }));
                } else {
                    setSoftErrList((prev) => ({
                        ...prev,
                        q7Full_med_records_post_avaSelect: false,
                    }));
                }
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: parseInt(value),
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q7Empty: false
                }))
                break;
            }
            case "q11Year_of_death": {
                setPtDetails((prev) => ({
                    ...prev,
                    q11Death_year_unk: false,
                    q11Year_of_deathRadio: true
                }));
                break;
            }
            case "q11Death_year_unk": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: !prev.q11Death_year_unk,
                    q11Year_of_death: "",
                    q11Year_of_deathRadio: false//remove
                }));
                setDefyearQ11(true);
                setHardErrList((prev) => ({
                    ...prev,
                    q11Empty: false,
                    q11LessThanIndexDt: false,
                    q11LessThanIndexYr: false,
                    //q8AfterDeath: false
                }))
                break;
            }
            case "q14Comorb_cpd": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: !prev.q14Comorb_cpd,
                    q14Comorb_none: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q14Empty: false,
                }))
                break;
            }
            case "q14Comorb_diabetes": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: !prev.q14Comorb_diabetes,
                    q14Comorb_none: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q14Empty: false,
                }))
                break;
            }
            case "q14Comorb_renal_failure": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: !prev.q14Comorb_renal_failure,
                    q14Comorb_none: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q14Empty: false,
                }))
                break;
            }
            case "q14Comorb_vasc_events": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: !prev.q14Comorb_vasc_events,
                    q14Comorb_none: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q14Empty: false,
                }))
                break;
            }
            case "q14Comorb_hep_c": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: !prev.q14Comorb_hep_c,
                    q14Comorb_none: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q14Empty: false,
                }))
                break;
            }
            case "q14Comorb_hiv": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: !prev.q14Comorb_hiv,
                    q14Comorb_none: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q14Empty: false,
                }))
                break;
            }
            case "q14Comorb_any_malig": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: !prev.q14Comorb_any_malig,
                    q14Comorb_none: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q14Empty: false,
                }))
                break;
            }
            case "q14Comorb_cld": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: !prev.q14Comorb_cld,
                    q14Comorb_none: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q14Empty: false,
                }))
                break;
            }
            case "q14Comorb_none": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: !prev.q14Comorb_none,
                    q14Comorb_cpd: false,
                    q14Comorb_diabetes: false,
                    q14Comorb_renal_failure: false,
                    q14Comorb_vasc_events: false,
                    q14Comorb_hep_c: false,
                    q14Comorb_hiv: false,
                    q14Comorb_any_malig: false,
                    q14Comorb_cld: false,
                    q14Comorb_hema_oth: false,
                    q14Comorb_hema_oth_txt: "",
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q14Empty: false,
                }))
                break;
            }
            case "q14Comorb_hema_oth": {
                {
                    setPtDetails((prev) => ({
                        ...prev,
                        [name]: !prev.q14Comorb_hema_oth,
                        q14Comorb_hema_oth_txt: "",
                        q14Comorb_none: false,
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        q14Empty: false, q14EmptyOtherTxt: false
                    }))
                }
                break;
            }
            case "q14Comorb_hema_oth_txt": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: value,
                    q14Comorb_hema_oth: true,
                    q14Comorb_none: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q14Empty: false, q14EmptyOtherTxt: false
                }))
                break;
            }
            case "q15Pt_weight": {
                if (value !== "" && AllowOnlyIntegers(value)) {//AllowPositiveDecimalNumbers
                    setPtDetails((prev) => ({
                        ...prev,
                        [name]: value
                    }));
                }
                else {
                    setPtDetails((prev) => ({
                        ...prev,
                        [name]: ""
                    }));
                }

                setPtDetails((prev) => ({
                    ...prev,
                    q15Pt_weight_unk: false,
                }))
                setHardErrList((prev) => ({
                    ...prev,
                    q15Empty: false// prev.q15Pt_weight_unitSelect !== 0 && prev.q15Pt_weight_unitSelect !== "",
                }))
                CheckWeight(value, ptDetails.q15Pt_weight_unitSelect);
                break;
            }
            case "q15Pt_weight_unk": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: !prev.q15Pt_weight_unk,
                    q15Pt_weight: "",
                    q15Pt_weight_unitSelect: 0//"0"
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q15Empty: false,
                    q15EmptyUnit: false
                }))
                setSoftErrList((prev) => ({
                    ...prev,
                    q15WeightCheck: false
                }))
                break;
            }
            case "q15Pt_weight_unitSelect": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: parseInt(value),
                    q15Pt_weight_unk: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q15EmptyUnit: false,
                }))
                CheckWeight(ptDetails.q15Pt_weight, parseInt(value));
                break;
            }
            default:
                break;

        }
    };
    function CheckWeight(weight, unit) {

        if (weight !== "" && unit !== 0 &&
            ((unit === 1 && (Number(weight) <= 12 || Number(weight) >= 900))//lbs
                || (unit === 2 && (Number(weight) <= 5 || Number(weight) >= 408)))//kgs      
        )
            setSoftErrList((prev) => ({
                ...prev,
                q15WeightCheck: true,
            }));
        else
            setSoftErrList((prev) => ({
                ...prev,
                q15WeightCheck: false,
            }));

    }
    /* <summary>
    date: 13-10-2023
    Name: AP
    description: date change handler. Sets state for conditional questions also.
    Q3 Index date, Q8 Last record date
    monthOrYearSelect: 1 => month, 2 => year
    <summary>*/
    const HandleDateChange = (name, date, optionId, monthOrYearSelect) => {

        switch (name) {
            case "q8Last_record_date": {


                let q3Dt = new Date(ptDetails.q3TmtInitiationDtSelect === 1 ? ptDetails.q3Index_date : ptDetails.q3TmtInitiationDtSelect === 2 ? ptDetails.q3Index_month_yr : "");
                let indexdate = new Date(q3Dt);
                let lastrecordDate = new Date(date);
                let days = Math.floor((lastrecordDate - indexdate) / (1000 * 60 * 60 * 24))


                setPtDetails((prev) => ({
                    ...prev,
                    [name]: date,
                    q13VisibleBasedonQ8: (days > 180) ? true : false,
                    q12Pt_still_at_clinicSelect: (days > 180) ? prev.q12Pt_still_at_clinicSelect : "",

                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q8Empty: false, q8AfterToday: false,
                    q8Beforeq3Date: false,
                    //q8AfterDeath: false
                }))
                setSoftErrList((prev) => ({
                    ...prev,
                    q9Terminate: false,
                    q10Terminate: false
                }))
                break;
            }
            case "q11Year_of_death": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: date,
                    q11Year_of_deathRadio: true//
                }));
                setPtDetails((prev) => ({
                    ...prev,
                    q11Death_year_unk: false,
                }));
                setDefyearQ11(false)
                setHardErrList((prev) => ({
                    ...prev,
                    q11Empty: false, q11LessThanIndexDt: false, q11LessThanIndexYr: false
                }))
                break;
            }
            case "yob": {
                setPtDetails((prev) => ({
                    ...prev,
                    [name]: date,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q1Empty: false,
                    q1InvalidYear: false,
                }))
                break;
            }
            case "q2Diagnosis_dt":
            case "q2Diagnosis_yr":
            case "q3Index_date":
            case "q2Diagnosis_month_yr":
            case "q3Index_month_yr":
                {

                    if (name === "q2Diagnosis_dt" || name === "q2Diagnosis_yr" || name === "q3Index_date") {

                        if (name === "q2Diagnosis_dt" || name === "q2Diagnosis_yr") {
                            setPtDetails((prev) => ({
                                ...prev,
                                [name]: date,
                            }));
                            setHardErrList((prev) => ({
                                ...prev,
                                q2Empty: false,
                                q2LessThanYob: false,
                                q2AfterToday: false
                            }))
                            setSoftErrList((prev) => ({
                                ...prev,
                                q2Terminate: false
                            }));
                        }

                        if (name === "q3Index_date") {
                            setPtDetails((prev) => ({
                                ...prev,
                                [name]: date,
                            }));
                            functionQ3DateSoftError(date, 0);
                            setHardErrList((prev) => ({
                                ...prev,
                                q3Empty: false,
                                q11LessThanIndexYr: false,
                                q3AfterToday: false
                            }))
                        }
                    }

                    if (name === "q3Index_date" || name === "q3Index_month_yr") {//Show-Hide Q4 to Q15
                        setShowBasedOnQ3Response(true);
                    }

                    if (name === "q2Diagnosis_month_yr") {
                        setPtDetails((prev) => ({
                            ...prev,
                            [name]: monthOrYearSelect === 1 ? prev.q2Diagnosis_month_yr.replace(prev.q2Diagnosis_month_yr.split('/')[0], date)
                                : monthOrYearSelect === 2 ? prev.q2Diagnosis_month_yr.replace(prev.q2Diagnosis_month_yr.split('/').pop(), date) :
                                    prev.q2Diagnosis_month_yr,
                        }));
                        setSoftErrList((prev) => ({
                            ...prev,
                            q2Terminate: false
                        }));
                        setHardErrList((prev) => ({
                            ...prev,
                            q2Empty: false,
                            q2LessThanYob: false,
                            q2AfterToday: false
                        }))


                    }
                    else if (name === "q3Index_month_yr") {

                        setPtDetails((prev) => ({
                            ...prev,
                            [name]: monthOrYearSelect === 1 ? prev.q3Index_month_yr.replace(prev.q3Index_month_yr.split('/')[0], date)
                                : monthOrYearSelect === 2 ? prev.q3Index_month_yr.replace(prev.q3Index_month_yr.split('/').pop(), date)
                                    : prev.q3Index_month_yr,
                        }));



                        functionQ3DateSoftError(date, monthOrYearSelect);
                        setHardErrList((prev) => ({
                            ...prev,
                            q3Empty: false, q11LessThanIndexYr: false, q3lessThanDiagnosisDt: false, q3AfterToday: false
                        }))
                        if (monthOrYearSelect == 1) {
                            if (ptDetails.q3Index_month_yr?.includes("YYYY"))
                                setShowBasedOnQ3Response(false);
                            else
                                setShowBasedOnQ3Response(true);
                        }
                        else if (monthOrYearSelect == 2) {
                            if (ptDetails.q3Index_month_yr?.includes("MM"))
                                setShowBasedOnQ3Response(false);
                            else
                                setShowBasedOnQ3Response(true);

                        }
                        else if (ptDetails.q3Index_month_yr?.includes("MM") && (ptDetails.q3Index_month_yr?.includes("YYYY")))
                            setShowBasedOnQ3Response(false);
                        else
                            setShowBasedOnQ3Response(true);
                    }

                    if (name.includes("q2Diagnosis")) {
                        setPtDetails((prev) => ({
                            ...prev,
                            q2PrimaryITPDiagnosisSelect: Number(optionId),// String(optionId),
                        }));
                        switch (name.split('_')[1]) {
                            case "dt": {
                                setPtDetails((prev) => ({
                                    ...prev,
                                    q2Diagnosis_month_yr: "MM/15/YYYY",//"",
                                    q2Diagnosis_yr: ""
                                }));
                                setDefDateQ2(false)
                                setDefYearQ2(true)
                                setDefYearMonthQ2(true)
                                break;
                            } case "month": {//year month op 2
                                setPtDetails((prev) => ({
                                    ...prev,
                                    q2Diagnosis_dt: "MM/DD/YYYY",//"",
                                    q2Diagnosis_yr: ""
                                }));
                                setDefDateQ2(true)
                                setDefYearQ2(true)
                                setDefYearMonthQ2(false)
                                break;
                            }
                            case "yr": {
                                setPtDetails((prev) => ({
                                    ...prev,
                                    q2Diagnosis_dt: "MM/DD/YYYY",//"",
                                    q2Diagnosis_month_yr: "MM/15/YYYY",//""
                                }));
                                setDefDateQ2(true)
                                setDefYearQ2(false)
                                setDefYearMonthQ2(true)
                                break;
                            }
                            default:
                                break
                        }
                    }
                    else if (name.includes("q3Index")) {

                        setPtDetails((prev) => ({
                            ...prev,
                            q3TmtInitiationDtSelect: Number(optionId), //String(optionId),
                        }));
                        switch (name.split('_')[1]) {
                            case "date": {
                                setPtDetails((prev) => ({
                                    ...prev,
                                    q3Index_month_yr: "MM/15/YYYY",
                                }));
                                setDefDateQ3(false)
                                setDefYearMonthQ3(true)
                                break;
                            } case "month": {
                                setPtDetails((prev) => ({
                                    ...prev,
                                    q3Index_date: "MM/DD/YYYY",//"",
                                }));
                                setDefDateQ3(true)
                                setDefYearMonthQ3(false)
                                break;
                            }
                            default:
                                break
                        }

                    }

                    break;
                }
            default:
                break;
        }
    };

    useEffect(() => {

        setSoftErrList((prev) => ({
            ...prev,
            q10Terminate: false
        }))
        if (showQ9 === false) {
            setPtDetails((prev) => ({
                ...prev,
                q9Pt_still_aliveSelect: "",
                q10Death_6mo_post_avaSelect: "",
                q11Year_of_death: "",
                q11Year_of_deathRadio: false,
                q11Death_year_unk: false
            }))
        }
    }, [showQ9])




    const ValidateForm = () => {

        let result = true;
        if ((ptDetails.yob ?? "") === "" || ptDetails.yob === "YYYY") {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                q1Empty: true
            }))
        }


        if ((ptDetails.q2PrimaryITPDiagnosisSelect === "" || ptDetails.q2PrimaryITPDiagnosisSelect === null)
            || (ptDetails.q2PrimaryITPDiagnosisSelect === 1 && ptDetails.q2Diagnosis_dt === "") ||
            (ptDetails.q2PrimaryITPDiagnosisSelect === 2 && (ptDetails.q2Diagnosis_month_yr?.includes("MM") || ptDetails.q2Diagnosis_month_yr?.includes("YYYY"))) ||
            (ptDetails.q2PrimaryITPDiagnosisSelect === 3 && ptDetails.q2Diagnosis_yr === "")) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                q2Empty: true
            }))
        }
        else {
            let dtq2 = "";
            if (ptDetails.q2PrimaryITPDiagnosisSelect === 1)
                dtq2 = new Date(ptDetails.q2Diagnosis_dt)
            else if (ptDetails.q2PrimaryITPDiagnosisSelect === 2) {
                dtq2 = new Date(ptDetails.q2Diagnosis_month_yr)
            }
            else if (ptDetails.q2PrimaryITPDiagnosisSelect === 3) {
                dtq2 = new Date(ptDetails.q2Diagnosis_yr)
            }
            //new Date(ptDetails.q2PrimaryITPDiagnosisSelect === 1 ? ptDetails.q2Diagnosis_dt : ptDetails.q2PrimaryITPDiagnosisSelect === 2 ? ptDetails.q2Diagnosis_month_yr? ptDetails.q2PrimaryITPDiagnosisSelect === 3:ptDetails.q2Diagnosis_yr : "");
            let dtYob = Number(ptDetails.yob)
            if ((dtYob !== "" && dtq2 !== "" && !isNaN(dtq2) && !isNaN(dtYob) && dtq2.getFullYear() < dtYob)) {
                result = false
                setHardErrList((prev) => ({
                    ...prev,
                    q2LessThanYob: true
                }))
            }
            //compare full date and partial
            if (ptDetails.q2PrimaryITPDiagnosisSelect === 1 || ptDetails.q2PrimaryITPDiagnosisSelect === 2) {
                if (IsDateAfterToday(dtq2)) {
                    result = false
                    setHardErrList((prev) => ({
                        ...prev,
                        q2AfterToday: true
                    }))
                }
            }
        }


        if (ptDetails.q3TmtInitiationDtSelect === "" || ptDetails.q3TmtInitiationDtSelect === null
            || (ptDetails.q3TmtInitiationDtSelect === 1 && ptDetails.q3Index_date === "") ||
            (ptDetails.q3TmtInitiationDtSelect === 2 && (ptDetails.q3Index_month_yr?.includes("MM") || ptDetails.q3Index_month_yr?.includes("YYYY")))) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                q3Empty: true,
                q3lessThanDiagnosisDt: false
            }))
        }
        else {
            let dtq3 = new Date(ptDetails.q3TmtInitiationDtSelect === 1 ? ptDetails.q3Index_date : ptDetails.q3TmtInitiationDtSelect === 2 ? ptDetails.q3Index_month_yr : "");
            //let dtq2 = new Date(ptDetails.q2PrimaryITPDiagnosisSelect === 1 ? ptDetails.q2Diagnosis_dt : ptDetails.q2PrimaryITPDiagnosisSelect === 2 ? ptDetails.q2Diagnosis_month_yr : ptDetails.q2PrimaryITPDiagnosisSelect === 3 ? ptDetails.q2Diagnosis_yr : "");

            let dtq2 = "";
            if (ptDetails.q2PrimaryITPDiagnosisSelect === 1)
                dtq2 = new Date(ptDetails.q2Diagnosis_dt)
            else if (ptDetails.q2PrimaryITPDiagnosisSelect === 2) {
                dtq2 = new Date(ptDetails.q2Diagnosis_month_yr)
            }
            else if (ptDetails.q2PrimaryITPDiagnosisSelect === 3) {
                dtq2 = new Date(ptDetails.q2Diagnosis_yr)
            }

            let currentYear = (new Date(dtq3).getFullYear());
            let totalYears = currentYear - parseInt(ptDetails.yob);
            if (totalYears < 18) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    q1InvalidYear: true
                }))
            }


            if ((!isNaN(dtq2) && !isNaN(dtq3) && dtq3 < dtq2)) {//&& dtq2.getFullYear() < dtq3.getFullYear()
                result = false
                setHardErrList((prev) => ({
                    ...prev,
                    q3lessThanDiagnosisDt: true
                }))
            } else {
                setHardErrList((prev) => ({
                    ...prev,
                    q3lessThanDiagnosisDt: false
                }))
            }
            //compare full date and partial
            if (ptDetails.q3TmtInitiationDtSelect === 1 || ptDetails.q3TmtInitiationDtSelect === 2) {
                if (IsDateAfterToday(dtq2)) {
                    result = false
                    setHardErrList((prev) => ({
                        ...prev,
                        q3AfterToday: true
                    }))
                }
            }
        }

        if (showBasedOnQ3Response) {
            if (ptDetails.q4Full_med_records_pre_avaSelect === "" || ptDetails.q4Full_med_records_pre_avaSelect === null) {
                result = false
                setHardErrList((prev) => ({
                    ...prev,
                    q4Empty: true
                }))
            }
            if (ptDetails.q5Pc_record_pre_indexSelect === "" || ptDetails.q5Pc_record_pre_indexSelect === null) {
                result = false
                setHardErrList((prev) => ({
                    ...prev,
                    q5Empty: true
                }))
            }
        }

        if (ptDetails.q6Ava_trial_participationSelect === "" || ptDetails.q6Ava_trial_participationSelect === null) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                q6Empty: true
            }))
        }

        if (showBasedOnQ3Response) {
            if (ptDetails.q7Full_med_records_post_avaSelect === "" || ptDetails.q7Full_med_records_post_avaSelect === null) {
                result = false
                setHardErrList((prev) => ({
                    ...prev,
                    q7Empty: true
                }))
            }
            if (ptDetails.q8Last_record_date === "" || ptDetails.q8Last_record_date === null) {

                result = false
                setHardErrList((prev) => ({
                    ...prev,
                    q8Empty: true
                }))
            } else {
                const dtq8 = new Date(q8Last_record_date);
                let q3Dt = new Date(ptDetails.q3TmtInitiationDtSelect === 1 ? ptDetails.q3Index_date : ptDetails.q3TmtInitiationDtSelect === 2 ? ptDetails.q3Index_month_yr : "");
                let indexdate = new Date(q3Dt);

                if (IsDateAfterToday(dtq8)) {
                    result = false
                    setHardErrList((prev) => ({
                        ...prev,
                        q8AfterToday: true
                    }))
                }
                else if (dtq8 < indexdate) {
                    result = false
                    setHardErrList((prev) => ({
                        ...prev,
                        q8Beforeq3Date: true
                    }))
                }
            }
            if (showQ9) {
                if (ptDetails.q9Pt_still_aliveSelect === "" || ptDetails.q9Pt_still_aliveSelect === null) {
                    result = false
                    setHardErrList((prev) => ({
                        ...prev,
                        q9Empty: true,
                    }))
                }
            }
            if (showQ9 && q9Pt_still_aliveSelect === 0) {
                if (ptDetails.q10Death_6mo_post_avaSelect === "" || ptDetails.q10Death_6mo_post_avaSelect === null) {
                    result = false
                    setHardErrList((prev) => ({
                        ...prev,
                        q10Empty: true,
                    }))
                }
            }

            if (showQ9 && ptDetails.q10Death_6mo_post_avaSelect === 1) {
                if (((ptDetails.q11Year_of_death === "" && ptDetails.q11Death_year_unk === false) || (ptDetails.q11Death_year_unk === null && ptDetails.q11Year_of_death === null))) {//consider select
                    result = false
                    setHardErrList((prev) => ({
                        ...prev,
                        q11Empty: true,
                    }))
                } else {
                    let dtq3 = new Date(ptDetails.q3TmtInitiationDtSelect === 1 ? ptDetails.q3Index_date : ptDetails.q3TmtInitiationDtSelect === 2 ? ptDetails.q3Index_month_yr : "");
                    let dtq11Year = ptDetails.q11Death_year_unk === false ? parseInt(ptDetails.q11Year_of_death) : ""
                    if ((dtq11Year !== "" && !isNaN(dtq11Year) && !isNaN(dtq3) && dtq11Year < dtq3.getFullYear())) {
                        result = false
                        setHardErrList((prev) => ({
                            ...prev,
                            q11LessThanIndexYr: true
                        }))
                    }
                }
            }
            // if (showQ9 && (q9Pt_still_aliveSelect === 1 || q9Pt_still_aliveSelect === 99)) {

            let q3Dt = new Date(ptDetails.q3TmtInitiationDtSelect === 1 ? ptDetails.q3Index_date : ptDetails.q3TmtInitiationDtSelect === 2 ? ptDetails.q3Index_month_yr : "");
            let indexdate = new Date(q3Dt);
            let lastrecordDate = new Date(ptDetails.q8Last_record_date);
            let days = Math.floor((lastrecordDate - indexdate) / (1000 * 60 * 60 * 24))

            if (days > 180) {
                if ((ptDetails.q12Pt_still_at_clinicSelect === null) || ptDetails.q12Pt_still_at_clinicSelect === "" || ptDetails.q12Pt_still_at_clinicSelect === null) {
                    result = false
                    setHardErrList((prev) => ({
                        ...prev,
                        q12Empty: true,
                    }))
                }
                if (ptDetails.q12Pt_still_at_clinicSelect === 0) {
                    if ((ptDetails.q13Pt_left_clinic_reasonSelect === null) || ptDetails.q13Pt_left_clinic_reasonSelect === "" || (ptDetails.q13Pt_left_clinic_reasonSelect === 5 && ptDetails.q13Pt_left_clinic_reason_oth_txt === "")) {
                        result = false
                        setHardErrList((prev) => ({
                            ...prev,
                            q13Empty: true,
                        }))
                    }
                }
            }

            //Q15

            if (
                (ptDetails.q15Pt_weight_unk === "" || ptDetails.q15Pt_weight_unk === false) && ptDetails.q15Pt_weight === "") {
                result = false
                setHardErrList((prev) => ({
                    ...prev,
                    q15Empty: true,
                }))
            } else {
                if ((ptDetails.q15Pt_weight_unk === "" || ptDetails.q15Pt_weight_unk === false) && ptDetails.ptWeight !== "" && (ptDetails.q15Pt_weight_unitSelect === "" || ptDetails.q15Pt_weight_unitSelect === 0)) {
                    result = false
                    setHardErrList((prev) => ({
                        ...prev,
                        q15EmptyUnit: true,
                    }))
                }
            }

        }
        //Q14
        //chk
        if ((ptDetails.q14Comorb_cpd === "" && ptDetails.q14Comorb_diabetes === "" && ptDetails.q14Comorb_renal_failure === "" &&
            ptDetails.q14Comorb_vasc_events === "" && ptDetails.q14Comorb_hep_c === "" && ptDetails.q14Comorb_hiv === "" &&
            ptDetails.q14Comorb_any_malig === "" && ptDetails.q14Comorb_cld === "" && ptDetails.q14Comorb_hema_oth === "" && ptDetails.q14Comorb_none === "")
            ||
            (ptDetails.q14Comorb_cpd === false && ptDetails.q14Comorb_diabetes === false && ptDetails.q14Comorb_renal_failure === false &&
                ptDetails.q14Comorb_vasc_events === false && ptDetails.q14Comorb_hep_c === false && ptDetails.q14Comorb_hiv === false &&
                ptDetails.q14Comorb_any_malig === false && ptDetails.q14Comorb_cld === false && ptDetails.q14Comorb_hema_oth === false && ptDetails.q14Comorb_none == false)
        ) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                q14Empty: true,
            }))
        }
        if (ptDetails.q14Comorb_hema_oth === true && ptDetails.q14Comorb_hema_oth_txt === "") {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                q14EmptyOtherTxt: true,
            }))
        }



        setIsAllDataFilled(result);
        return result;
    }
    const SaveForm = async () => {
        try {
            ToggleLoader(true);

            //sifter 11172
            setPtDetails((prev) => ({
                ...prev,
                metInclusionCriteria: null
            }))

            //remove soft errors on click of Save
            setSoftErrList(InitialSoftErrList);
            setHardErrList(InitialHardErrList);


            if (!ValidateForm()) {
                setShowTopErrMsg(true)
                ToggleLoader(false);
                return false;
            }
            setShowTopErrMsg(false);
            const patientModel = {
                patientId: pid ?? 0,
                userId: GetLoggedInUserID() ?? 0,
                metInclusionCriteria: ValidateEligibility(),
                yob: Number(ptDetails.yob),
                primaryItpDiagnosis: Number(ptDetails.q2PrimaryITPDiagnosisSelect),
                diagnosisDt: ptDetails.q2PrimaryITPDiagnosisSelect === 1 ? ptDetails.q2Diagnosis_dt : ptDetails.q2PrimaryITPDiagnosisSelect === 2 ? ptDetails.q2Diagnosis_month_yr : null,
                diagnosisYr: ptDetails.q2Diagnosis_yr,
                dateTmtInitiationWithAva: Number(ptDetails.q3TmtInitiationDtSelect),
                indexDate: ptDetails.q3TmtInitiationDtSelect === 1 ? ptDetails.q3Index_date : ptDetails.q3TmtInitiationDtSelect === 2 ? ptDetails.q3Index_month_yr : null,
                fullMedRecordsPreAva: Number(ptDetails.q4Full_med_records_pre_avaSelect),
                pcRecordPreIndex: Number(ptDetails.q5Pc_record_pre_indexSelect),
                avaTrialParticipation: Number(ptDetails.q6Ava_trial_participationSelect),
                fullMedRecordsPostAva: Number(ptDetails.q7Full_med_records_post_avaSelect),
                lastRecordDate: ptDetails.q8Last_record_date,
                ptStillAlive: showQ9 ? Number(ptDetails.q9Pt_still_aliveSelect) : null,//Q9
                death6moPostAva: showQ9 && q9Pt_still_aliveSelect === 0 ? Number(ptDetails.q10Death_6mo_post_avaSelect) : null,//Q10
                yearOfDeath: showQ9 && q9Pt_still_aliveSelect === 0 && ptDetails.q10Death_6mo_post_avaSelect === 1 ? Number(ptDetails.q11Year_of_death) : null,//Q11
                deathYearUnk: showQ9 && q9Pt_still_aliveSelect === 0 && ptDetails.q10Death_6mo_post_avaSelect === 1 ? ptDetails.q11Death_year_unk : null,//Q11
                ptStillAtClinic: Number(ptDetails.q12Pt_still_at_clinicSelect),//Q12
                ptLeftClinicReason: Number(ptDetails.q13Pt_left_clinic_reasonSelect),//Q13
                ptLeftClinicReasonOth: ptDetails.q13Pt_left_clinic_reason_oth_txt,//Q13
                comorbCpd: ptDetails.q14Comorb_cpd,
                comorbDiabetes: ptDetails.q14Comorb_diabetes,
                comorbRenalFailure: ptDetails.q14Comorb_renal_failure,
                comorbVascEvents: ptDetails.q14Comorb_vasc_events,
                comorbHepC: ptDetails.q14Comorb_hep_c,
                comorbHiv: ptDetails.q14Comorb_hiv,
                comorbAnyMalig: ptDetails.q14Comorb_any_malig,
                comorbCld: ptDetails.q14Comorb_cld,
                comorbNone: ptDetails.q14Comorb_none,
                comorbHemaOth: ptDetails.q14Comorb_hema_oth,
                comorbHemaOthTxt: ptDetails.q14Comorb_hema_oth_txt,
                ptWeight: parseInt(ptDetails.q15Pt_weight),//ptDetails.q15Pt_weight,
                ptWeightUnit: ptDetails.q15Pt_weight_unitSelect===0?null:WeightUnit[ptDetails.q15Pt_weight_unitSelect],
                ptWeightUnk: ptDetails.q15Pt_weight_unk,

                indexDateOldValue: ptDetails.q3Index_date_old_val,
                lastRecordDateOldValue: ptDetails.q8Last_record_date_old_val
            }
            const response = await PatientService.SavePatient(patientModel);

            //ToggleLoader(false);
            if (response.status !== HTTPResponse.OK) {

                if (response.status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else
                    throw response.error;
            } else {
                //AddLocalStorageData("patientId", response?.data?.patientId);//remove
                setPtDetails((prev) => ({
                    ...prev,
                    metInclusionCriteria: response?.data?.metInclusionCriteria,
                    //assign the saved values to old 
                    q3Index_date_old_val: response?.data?.indexDate,
                    q8Last_record_date_old_val: response?.data?.lastRecordDate
                }))
                ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);

                //ToggleLoader(true);
                await LoadData();
                ToggleLoader(false);

                if (response?.data?.showDateChangeAffectAlert) {
                    //  // Set Patient Submit Value in LS
                    //  const ptArr = { ...patientData }
                    //  ptArr.submit = response?.data

                    //  AddLocalStorageData("patient", JSON.stringify(ptArr))
                    ShowAlertBox();
                }

                return response?.data?.metInclusionCriteria
            }
        } catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
        }
    }
    const ValidateEligibility = () => {

        try {
            let result = true;
            if (q2PrimaryITPDiagnosisSelect === 5
                || (index_date != "" && !isNaN(index_date) && (index_date < new Date("2019/07/01") || index_date > new Date("2023/03/31")))
                || q3TmtInitiationDtSelect === 3 || q3TmtInitiationDtSelect === 4
                || q4Full_med_records_pre_avaSelect === 0 || q4Full_med_records_pre_avaSelect === 99
                || q5Pc_record_pre_indexSelect === 0 || q5Pc_record_pre_indexSelect === 99
                || q6Ava_trial_participationSelect === 1 || q6Ava_trial_participationSelect === 99
                || q7Full_med_records_post_avaSelect === 0


                || (showQ9 && (q9Pt_still_aliveSelect === 1 || q9Pt_still_aliveSelect === 99))
                || (showQ9 && q9Pt_still_aliveSelect === 0 && (q10Death_6mo_post_avaSelect === 0 || q10Death_6mo_post_avaSelect === 99)))
                result = false;

            setIsEligible(result);

            return result;
        }
        catch {
        }
    }
    useEffect(() => {

        async function FetchData() {
            if (!isNaN(pid) && Number(pid) !== 0) {

                ToggleLoader(true);
                await LoadData();
                ToggleLoader(false);
            }
        }
        FetchData()
    }, [])

    async function LoadData() {
        try {


            const response = await PatientService.LoadPatient(pid);
            if (response?.status === HTTPResponse.OK) {
                const { data } = response;

                let q3Dt = new Date(data.indexDate);
                let indexdate = new Date(q3Dt);
                let lastrecordDate = new Date(data.lastRecordDate);
                let days = Math.floor((lastrecordDate - indexdate) / (1000 * 60 * 60 * 24))

                setPtDetails((prev) => ({
                    ...prev,
                    //siteId: data.siteId,
                    metInclusionCriteria: data.metInclusionCriteria,

                    yob: data.yob != null ? data.yob : "",// String(data.yob ?? "YYYY"),

                    q2PrimaryITPDiagnosisSelect: data.primaryItpDiagnosis,
                    q2Diagnosis_dt: data.primaryItpDiagnosis === 1 ? data.diagnosisDt : "",
                    q2Diagnosis_month_yr: data.primaryItpDiagnosis === 2 ? (data.diagnosisDt) : "MM/15/YYYY",//""
                    q2Diagnosis_yr: data.primaryItpDiagnosis === 3 ? data.diagnosisYr : "",//"YYYY",

                    q3TmtInitiationDtSelect: data.dateTmtInitiationWithAva,
                    q3Index_date: data.dateTmtInitiationWithAva === 1 ? data.indexDate : "",
                    q3Index_month_yr: data.dateTmtInitiationWithAva === 2 ? data.indexDate : "MM/15/YYYY",//""

                    q4Full_med_records_pre_avaSelect: data.fullMedRecordsPreAva,
                    q5Pc_record_pre_indexSelect: data.pcRecordPreIndex,
                    q6Ava_trial_participationSelect: data.avaTrialParticipation,
                    q7Full_med_records_post_avaSelect: data.fullMedRecordsPostAva,

                    q8Last_record_date: data.lastRecordDate,//data.lastRecordDate != null ? data.lastRecordDate : "",

                    q9Pt_still_aliveSelect: data.ptStillAlive,
                    q10Death_6mo_post_avaSelect: data.death6moPostAva,

                    q11Year_of_death: data.deathYearUnk != null && !data.deathYearUnk ? data.yearOfDeath : "",//String(data.yearOfDeath ?? "YYYY")
                    q11Year_of_deathRadio: data.deathYearUnk != null && !data.deathYearUnk,
                    q11Death_year_unk: data.deathYearUnk != null && data.deathYearUnk,

                    q12Pt_still_at_clinicSelect: data.ptStillAtClinic,

                    q13Pt_left_clinic_reasonSelect: data.ptLeftClinicReason,
                    q13Pt_left_clinic_reason_oth_txt: data.ptLeftClinicReason === 5 ? data.ptLeftClinicReasonOth ?? "" : "",

                    q14Comorb_cpd: data.comorbCpd != null && data.comorbCpd,
                    q14Comorb_diabetes: data.comorbDiabetes != null && data.comorbDiabetes,
                    q14Comorb_renal_failure: data.comorbRenalFailure != null && data.comorbRenalFailure,
                    q14Comorb_vasc_events: data.comorbVascEvents != null && data.comorbVascEvents,
                    q14Comorb_hep_c: data.comorbHepC != null && data.comorbHepC,
                    q14Comorb_hiv: data.comorbHiv != null && data.comorbHiv,
                    q14Comorb_any_malig: data.comorbAnyMalig != null && data.comorbAnyMalig,
                    q14Comorb_cld: data.comorbCld != null && data.comorbCld,
                    q14Comorb_none: data.comorbNone != null && data.comorbNone,
                    q14Comorb_hema_oth: data.comorbHemaOth != null && data.comorbHemaOth,
                    q14Comorb_hema_oth_txt: data.comorbHemaOth == true ? data.comorbHemaOthTxt ?? "" : "",

                    q15Pt_weight: data.ptWeight ?? "",
                    q15Pt_weight_unitSelect: !data.ptWeightUnk && data.ptWeightUnit != null ? GetWeightUnit(data.ptWeightUnit) : 0,
                    q15Pt_weight_unk: data.ptWeightUnk != null && data.ptWeightUnk,

                    q3Index_date_old_val: data.indexDateOldValue,//data.indexDate
                    q8Last_record_date_old_val: data.lastRecordDateOldValue,//data.lastRecordDate
                    q13VisibleBasedonQ8: (days > 180) ? true : false


                }))


                if (data.dateTmtInitiationWithAva === 1 || data.dateTmtInitiationWithAva === 2) {
                    setShowBasedOnQ3Response(true);
                }
                else {
                    setShowBasedOnQ3Response(false);

                }
                setIsLocked(data.isLocked)
            }else{                
                if (response?.status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else
                    throw response.error;
            }
        } catch (er) {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    }
    
    /* <summary>
    date: 10-11-2023
    Name: AP
    description: Show an alert message when other page(ava tmt) could be affected
    <summary>*/
    const ShowAlertBox = async () => {
        const choice = await ShowActionPopUp(
            "Alert", //title
            PatientScreening_Msgs.DateChangeAlert, //message
            "Ok", //Confirm button text
            true
        );
    };
    /* <summary>
    date: 01-12-2023
    Name: AP
    description: Gets the enum key by passing the value
    <summary>*/ 
    const GetWeightUnit = (unit) => {
        const result = Object.entries(WeightUnit)
          .filter(([key, value]) => value === unit)
          .map(([key, value]) => parseInt(key));      
        return result[0];
      };
    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SaveForm} />
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div className="row">
                    <SideBarMenu activePageId={SideMenuItems.PatientScreening} />
                    <div className="col-md-9 col-pad-left">
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>PATIENT SCREENING</h4>
                            </div>

                            {!showTopErrMsg && pid != 0 && ptDetails.metInclusionCriteria != null && (
                                ptDetails.metInclusionCriteria ?
                                    (<div className="survey-section-content-message">
                                        <p>
                                            {PatientScreening_Msgs.EligiblePatient}
                                        </p>
                                    </div>)
                                    :
                                    (<div className="clean-error">
                                        <p>
                                            {PatientScreening_Msgs.IneligiblePatient}
                                        </p>
                                    </div>)
                            )
                            }



                            {showTopErrMsg && <div className="clean-error ">
                                <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
                            </div>
                            }
                            <div className="survey-question-section">
                                <div className="survey-question-content">
                                    <div className="question-answer">
                                        <div className="question-bot-sapce single-dig-quest">
                                            <span className="question question-weight question-inline">
                                                <span>1.</span>
                                                <span className="quest-text-pad">Year of birth: </span>
                                            </span>
                                            <span className="" style={{ marginLeft: "4px" }}></span>
                                            <DateControl
                                                hideDay={true}
                                                hideMonth={true}
                                                ctrlId={"yob"}
                                                HandleDateChange={HandleDateChange}
                                                date={ptDetails.yob}
                                            />
                                            <ErrorField show={hardErrList.q1Empty} message={PatientScreening_Msgs.Q1} />
                                            <ErrorField show={hardErrList.q1InvalidYear} message={PatientScreening_Msgs.Q1InvalidYear} />
                                        </div>
                                        <div className="question-bot-sapce single-dig-quest">
                                            <div className="question question-weight">
                                                <span>2.</span>
                                                <span className="quest-text-pad">
                                                    Date of primary groats diagnosis:
                                                </span>
                                            </div>
                                            <div className="answer-pad-left">
                                                <div className="" style={{ display: "flex" }}>
                                                    <span className="">
                                                        <input
                                                            type="radio"
                                                            id="q2PrimaryITPDiagnosisSelect_1"
                                                            name="q2PrimaryITPDiagnosisSelect"
                                                            value={1}
                                                            checked={
                                                                ptDetails.q2PrimaryITPDiagnosisSelect === 1// "1"
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="q2PrimaryITPDiagnosisSelect_1">
                                                            <span className="radio-text-padding">
                                                                Exact date:{" "}
                                                            </span>
                                                        </label>
                                                    </span>
                                                    <DateControl
                                                        ctrlId={"q2Diagnosis_dt"}
                                                        HandleDateChange={HandleDateChange}
                                                        defDate={defDateQ2}
                                                        optionId={1}
                                                        date={ptDetails.q2Diagnosis_dt}
                                                    // onBlur={HandleFormFieldBlur}
                                                    />
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q2PrimaryITPDiagnosisSelect_2"
                                                        name="q2PrimaryITPDiagnosisSelect"
                                                        value={2}
                                                        checked={
                                                            ptDetails.q2PrimaryITPDiagnosisSelect === 2//"2"
                                                        }
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q2PrimaryITPDiagnosisSelect_2">
                                                        <span className="radio-text-padding">
                                                            Exact date is unknown, but month and year are
                                                            known:{" "}
                                                        </span>
                                                    </label>


                                                    <DateControl hideDay={true}
                                                        ctrlId={"q2Diagnosis_month_yr"}
                                                        HandleDateChange={HandleDateChange}
                                                        defYear={defYearMonthQ2}
                                                        defMonth={defYearMonthQ2}
                                                        optionId={2}
                                                        date={ptDetails.q2Diagnosis_month_yr}
                                                    // onBlur={HandleFormFieldBlur}
                                                    />
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q2PrimaryITPDiagnosisSelect_3"
                                                        name="q2PrimaryITPDiagnosisSelect"
                                                        value={3}
                                                        checked={
                                                            ptDetails.q2PrimaryITPDiagnosisSelect === 3// "3"
                                                        }
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q2PrimaryITPDiagnosisSelect_3">
                                                        <span className="radio-text-padding">
                                                            Only the year is known:{" "}
                                                        </span>
                                                    </label>
                                                    <DateControl hideDay="true" hideMonth="true"
                                                        ctrlId={"q2Diagnosis_yr"}
                                                        HandleDateChange={HandleDateChange}
                                                        defYear={defYearQ2}
                                                        optionId={3}
                                                        date={ptDetails.q2Diagnosis_yr}
                                                    // onBlur={HandleFormFieldBlur}
                                                    />
                                                </div>
                                                <div className="option-without-input">
                                                    <input
                                                        type="radio"
                                                        id="q2PrimaryITPDiagnosisSelect_4"
                                                        name="q2PrimaryITPDiagnosisSelect"
                                                        value={4}
                                                        checked={
                                                            ptDetails.q2PrimaryITPDiagnosisSelect === 4//"4"
                                                        }
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q2PrimaryITPDiagnosisSelect_4">
                                                        <span className="radio-text-padding">
                                                            Date of primary groats diagnosis is unknown
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="option-without-input">
                                                    <input
                                                        type="radio"
                                                        id="q2PrimaryITPDiagnosisSelect_5"
                                                        name="q2PrimaryITPDiagnosisSelect"
                                                        value={5}
                                                        checked={
                                                            ptDetails.q2PrimaryITPDiagnosisSelect === 5// "5"
                                                        }
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q2PrimaryITPDiagnosisSelect_5">
                                                        <span className="radio-text-padding">
                                                            This patient was not diagnosed with groats
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={hardErrList.q2Empty} message={PatientScreening_Msgs.Q2} />
                                            <ErrorField show={hardErrList.q2LessThanYob} message={PatientScreening_Msgs[100]} />
                                            <ErrorField show={hardErrList.q2AfterToday} message={PatientScreening_Msgs.Q2AfterToday} />
                                            <ErrorField show={softErrList.q2Terminate} message={PatientScreening_Msgs.INELIGIBLE} />
                                        </div>
                                        <div className="question-bot-sapce single-dig-quest">
                                            <div className="question question-weight">
                                                <span>3.</span>
                                                <span className="quest-text-pad">
                                                    Date of treatment X initiation:
                                                </span>
                                            </div>
                                            <div className="answer-pad-left">
                                                <div className="" style={{ display: "flex" }}>
                                                    <input
                                                        type="radio"
                                                        id="q3TmtInitiationDtSelect_1"
                                                        name="q3TmtInitiationDtSelect"
                                                        value={1}
                                                        checked={ptDetails.q3TmtInitiationDtSelect === 1//"1"
                                                        }
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q3TmtInitiationDtSelect_1">
                                                        <span className="radio-text-padding">
                                                            Exact date:{" "}
                                                        </span>
                                                    </label>
                                                    <DateControl
                                                        ctrlId={"q3Index_date"}
                                                        HandleDateChange={HandleDateChange}
                                                        defDate={defDateQ3}
                                                        optionId={1}
                                                        date={ptDetails.q3Index_date}
                                                    />
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q3TmtInitiationDtSelect_2"
                                                        name="q3TmtInitiationDtSelect"
                                                        value={2}
                                                        checked={ptDetails.q3TmtInitiationDtSelect === 2//"2"
                                                        }
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q3TmtInitiationDtSelect_2">
                                                        <span className="radio-text-padding">
                                                            Exact date is unknown, but month and year are
                                                            known:{" "}
                                                        </span>
                                                    </label>
                                                    <DateControl hideDay={true}
                                                        ctrlId={"q3Index_month_yr"}
                                                        HandleDateChange={HandleDateChange}
                                                        defYear={defYearMonthQ3}
                                                        defMonth={defYearMonthQ3}
                                                        optionId={2}
                                                        date={ptDetails.q3Index_month_yr} />
                                                </div>
                                                <div className="option-without-input">
                                                    <input
                                                        type="radio"
                                                        id="q3TmtInitiationDtSelect_3"
                                                        name="q3TmtInitiationDtSelect"
                                                        value={3}
                                                        checked={ptDetails.q3TmtInitiationDtSelect === 3//"3"
                                                        }
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q3TmtInitiationDtSelect_3">
                                                        <span className="radio-text-padding">
                                                            Date of treatment X initiation is unknown
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="option-without-input">
                                                    <input
                                                        type="radio"
                                                        id="q3TmtInitiationDtSelect_4"
                                                        name="q3TmtInitiationDtSelect"
                                                        value={4}
                                                        checked={ptDetails.q3TmtInitiationDtSelect === 4//"4"
                                                        }
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q3TmtInitiationDtSelect_4">
                                                        <span className="radio-text-padding">
                                                            This patient was not treated with treatment X
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={hardErrList.q3Empty} message={PatientScreening_Msgs.Q3} />
                                            <ErrorField show={hardErrList.q3lessThanDiagnosisDt} message={PatientScreening_Msgs[111]} />
                                            <ErrorField show={hardErrList.q3AfterToday} message={PatientScreening_Msgs.Q3AfterToday} />
                                            <ErrorField show={softErrList.q3_indexDt_unk} message={PatientScreening_Msgs[106]} />
                                            <ErrorField show={softErrList.q3_pt_no_ava} message={PatientScreening_Msgs[107]} />
                                            <ErrorField show={softErrList.q3_indexDt_before} message={PatientScreening_Msgs[104]} />
                                            <ErrorField show={softErrList.q3_indexDt_after} message={PatientScreening_Msgs[105]} />
                                            <ErrorField show={softErrList.q3_below18} message={PatientScreening_Msgs[108]} />
                                            {/* <ErrorField show={softErrList.q3_indexDt_before} message={PatientScreening_Msgs[104]} />
                      <ErrorField show={softErrList.q3_indexDt_after} message={PatientScreening_Msgs[105]} />
                      <ErrorField show={softErrList.q3_indexDt_unk} message={PatientScreening_Msgs[106]} />
                      <ErrorField show={softErrList.q3_pt_no_ava} message={PatientScreening_Msgs[107]} />
                      <ErrorField show={softErrList.q3_below18} message={PatientScreening_Msgs[108]} />
                      <ErrorField show={softErrList.q3Terminate} message={PatientScreening_Msgs.INELIGIBLE} /> */}
                                        </div>

                                        {showBasedOnQ3Response &&
                                            <>
                                                {/*Q4 Start*/}
                                                < div className="question-bot-sapce single-dig-quest">
                                                    <div className="question question-weight">
                                                        <span>4.</span>
                                                        <span className="quest-text-pad">
                                                        Do you have complete medical records for this patient for at least 3 months before they initiated treatment X 
                                                        (i.e., from at least {" "}
                                                            <span
                                                                style={{ color: PageDesignConstants.fontColor }}
                                                            >
                                                                {index_dt_minus_3mo}
                                                            </span>{" "}or earlier)? “Complete medical records” include information on the patient's 
                                                        platelet counts (date and results), clinical history, and medications during this time period.

                                                        
                                                        </span>
                                                    </div>
                                                    <div className="answer-pad-left">
                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                id="q4Full_med_records_pre_avaSelect_1"
                                                                name="q4Full_med_records_pre_avaSelect"
                                                                value={1}
                                                                checked={
                                                                    ptDetails.q4Full_med_records_pre_avaSelect === 1//"1"
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <label htmlFor="q4Full_med_records_pre_avaSelect_1">
                                                                <span className="radio-text-padding">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                id="q4Full_med_records_pre_avaSelect_0"
                                                                name="q4Full_med_records_pre_avaSelect"
                                                                value={0}
                                                                checked={
                                                                    ptDetails.q4Full_med_records_pre_avaSelect === 0//"0"
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <label htmlFor="q4Full_med_records_pre_avaSelect_0">
                                                                <span className="radio-text-padding">No</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                id="q4Full_med_records_pre_avaSelect_99"
                                                                name="q4Full_med_records_pre_avaSelect"
                                                                value={99}
                                                                checked={
                                                                    ptDetails.q4Full_med_records_pre_avaSelect === 99//"99"
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <label htmlFor="q4Full_med_records_pre_avaSelect_99">
                                                                <span className="radio-text-padding">Unknown</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={hardErrList.q4Empty} message={PatientScreening_Msgs.Q4} />
                                                    <ErrorField show={softErrList.q4Terminate} message={PatientScreening_Msgs.INELIGIBLE} />
                                                </div>
                                                <div className="question-bot-sapce single-dig-quest">
                                                    <div className="question question-weight">
                                                        <span>5.</span>
                                                        <span className="quest-text-pad">
                                                        Do you have at least one platelet count record for
                                                            this patient between{" "}
                                                            <span
                                                                style={{ color: PageDesignConstants.fontColor }}
                                                            >
                                                                {index_dt_minus_3mo}
                                                            </span>{" "}
                                                            and{" "}
                                                            <span
                                                                style={{ color: PageDesignConstants.fontColor }}
                                                            >
                                                                {index_dt_minus_1_day}
                                                            </span>
                                                            ?
                                                        </span>
                                                    </div>
                                                    <div className="answer-pad-left">
                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                id="q5Pc_record_pre_indexSelect_1"
                                                                name="q5Pc_record_pre_indexSelect"
                                                                value={1}
                                                                checked={
                                                                    ptDetails.q5Pc_record_pre_indexSelect === 1//"1"
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <label htmlFor="q5Pc_record_pre_indexSelect_1">
                                                                <span className="radio-text-padding">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                id="q5Pc_record_pre_indexSelect_0"
                                                                name="q5Pc_record_pre_indexSelect"
                                                                value={0}
                                                                checked={
                                                                    ptDetails.q5Pc_record_pre_indexSelect === 0//"0"
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <label htmlFor="q5Pc_record_pre_indexSelect_0">
                                                                <span className="radio-text-padding">No</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                id="q5Pc_record_pre_indexSelect_99"
                                                                name="q5Pc_record_pre_indexSelect"
                                                                value={99}
                                                                checked={
                                                                    ptDetails.q5Pc_record_pre_indexSelect === 99//"99"
                                                                }
                                                                onChange={HandleFieldChange}

                                                            />
                                                            <label htmlFor="q5Pc_record_pre_indexSelect_99">
                                                                <span className="radio-text-padding">Unknown</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={hardErrList.q5Empty} message={PatientScreening_Msgs.Q5} />
                                                    <ErrorField show={softErrList.q5Terminate} message={PatientScreening_Msgs.INELIGIBLE} />
                                                </div>
                                            </>
                                        }
                                        <div className="question-bot-sapce single-dig-quest">
                                            <div className="question question-weight">
                                                <span>6.</span>
                                                <span className="quest-text-pad">
                                                Has the patient participated in any previous treatment X
                                                    clinical trials?
                                                </span>
                                            </div>
                                            <div className="answer-pad-left">
                                                <div className="option-without-input">
                                                    <input
                                                        type="radio"
                                                        id="q6Ava_trial_participationSelect_1"
                                                        name="q6Ava_trial_participationSelect"
                                                        value={1}
                                                        checked={
                                                            ptDetails.q6Ava_trial_participationSelect === 1//"1"
                                                        }
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q6Ava_trial_participationSelect_1">
                                                        <span className="radio-text-padding">Yes</span>
                                                    </label>
                                                </div>
                                                <div className="option-without-input">
                                                    <input
                                                        type="radio"
                                                        id="q6Ava_trial_participationSelect_0"
                                                        name="q6Ava_trial_participationSelect"
                                                        value={0}
                                                        checked={
                                                            ptDetails.q6Ava_trial_participationSelect === 0//"0"
                                                        }
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q6Ava_trial_participationSelect_0">
                                                        <span className="radio-text-padding">No</span>
                                                    </label>
                                                </div>
                                                <div className="option-without-input">
                                                    <input
                                                        type="radio"
                                                        id="q6Ava_trial_participationSelect_99"
                                                        name="q6Ava_trial_participationSelect"
                                                        value={99}
                                                        checked={
                                                            ptDetails.q6Ava_trial_participationSelect === 99//"99"
                                                        }
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q6Ava_trial_participationSelect_99">
                                                        <span className="radio-text-padding">Unknown</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={hardErrList.q6Empty} message={PatientScreening_Msgs.Q6} />
                                            <ErrorField show={softErrList.q6Terminate} message={PatientScreening_Msgs.INELIGIBLE} />

                                        </div>
                                        {showBasedOnQ3Response &&
                                            <>
                                                <div className="question-bot-sapce single-dig-quest">
                                                    <div className="question question-weight">
                                                        <span>7.</span>
                                                        <span className="quest-text-pad">
                                                        Do you have complete medical records for this patient for at least 6 months
                                                            following treatment X initiation or until the patient’s death (i.e., until{" "}
                                                            <span
                                                                style={{ color: PageDesignConstants.fontColor }}
                                                            >
                                                                {index_dt_plus_6mo}
                                                            </span>{" "}
                                                            or until the patient’s death, if the patient died before then)?
                                                        
                                                        </span>
                                                    </div>
                                                    <div className="answer-pad-left">
                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                id="q7Full_med_records_post_avaSelect_1"
                                                                name="q7Full_med_records_post_avaSelect"
                                                                value={1}
                                                                checked={
                                                                    ptDetails.q7Full_med_records_post_avaSelect === 1//"1"
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <label htmlFor="q7Full_med_records_post_avaSelect_1">
                                                                <span className="radio-text-padding">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                id="q7Full_med_records_post_avaSelect_0"
                                                                name="q7Full_med_records_post_avaSelect"
                                                                value={0}
                                                                checked={
                                                                    ptDetails.q7Full_med_records_post_avaSelect === 0//"0"
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <label htmlFor="q7Full_med_records_post_avaSelect_0">
                                                                <span className="radio-text-padding">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={hardErrList.q7Empty} message={PatientScreening_Msgs.Q7} />
                                                    <ErrorField show={softErrList.q7Full_med_records_post_avaSelect} message={PatientScreening_Msgs.INELIGIBLE} />

                                                </div>

                                                <div className="question-bot-sapce single-dig-quest">
                                                    <span className="question question-weight">
                                                        <div style={{ display: "flex" }}>
                                                            <span>8.</span>
                                                            <span className="quest-text-pad">
                                                            Please enter the latest date that you have complete
                                                                medical records for this patient:{" "}
                                                            </span>
                                                            <DateControl
                                                                ctrlId={"q8Last_record_date"}
                                                                HandleDateChange={HandleDateChange}
                                                                date={ptDetails.q8Last_record_date}
                                                            />
                                                        </div>
                                                    </span>

                                                    {/* <ErrorField show={softErrList.q8LessThanIndex6mo} message={PatientScreening_Msgs[109]} /> */}
                                                    <ErrorField show={hardErrList.q8Empty} message={PatientScreening_Msgs.Q8} />
                                                    <ErrorField show={hardErrList.q8AfterToday} message={PatientScreening_Msgs.Q8AfterToday} />
                                                    <ErrorField show={hardErrList.q8Beforeq3Date} message={PatientScreening_Msgs.Q8ABeforeQ3Date} />

                                                    {/* <ErrorField show={hardErrList.q8AfterDeath} message={PatientScreening_Msgs[101]} /> */}


                                                </div>

                                                {showQ9 && (
                                                    <div className="question-bot-sapce single-dig-quest">
                                                        <div className="question question-weight">
                                                            <span>9.</span>
                                                            <span className="quest-text-pad">
                                                                Is the patient still alive?
                                                            </span>
                                                        </div>
                                                        <div className="answer-pad-left">
                                                            <div className="option-without-input">
                                                                <input
                                                                    type="radio"
                                                                    id="q9Pt_still_aliveSelect_1"
                                                                    name="q9Pt_still_aliveSelect"
                                                                    value={1}
                                                                    checked={ptDetails.q9Pt_still_aliveSelect === 1//"1"
                                                                    }
                                                                    onChange={HandleFieldChange}
                                                                />
                                                                <label htmlFor="q9Pt_still_aliveSelect_1">
                                                                    <span className="radio-text-padding">Yes</span>
                                                                </label>
                                                            </div>
                                                            <div className="option-without-input">
                                                                <input
                                                                    type="radio"
                                                                    id="q9Pt_still_aliveSelect_0"
                                                                    name="q9Pt_still_aliveSelect"
                                                                    value={0}
                                                                    checked={ptDetails.q9Pt_still_aliveSelect === 0//"0"
                                                                    }
                                                                    onChange={HandleFieldChange}
                                                                />
                                                                <label htmlFor="q9Pt_still_aliveSelect_0">
                                                                    <span className="radio-text-padding">No</span>
                                                                </label>
                                                            </div>
                                                            <div className="option-without-input">
                                                                <input
                                                                    type="radio"
                                                                    id="q9Pt_still_aliveSelect_99"
                                                                    name="q9Pt_still_aliveSelect"
                                                                    value={99}
                                                                    checked={
                                                                        ptDetails.q9Pt_still_aliveSelect === 99//"99"
                                                                    }
                                                                    onChange={HandleFieldChange}
                                                                />
                                                                <label htmlFor="q9Pt_still_aliveSelect_99">
                                                                    <span className="radio-text-padding">
                                                                        Unknown
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <ErrorField show={hardErrList.q9Empty} message={PatientScreening_Msgs.Q9} />
                                                        <ErrorField show={softErrList.q9Terminate} message={PatientScreening_Msgs.INELIGIBLE} />

                                                    </div>
                                                )}
                                                {showQ9 && q9Pt_still_aliveSelect === 0//"0" 
                                                    && (
                                                        <div className="question-bot-sapce single-dig-quest">
                                                            <div className="question question-weight">
                                                                <span>10.</span>
                                                                <span className="quest-text-pad">
                                                                Did the patient die within 6 months of treatment X
                                                                    initiation (i.e., between{" "}
                                                                    <span
                                                                        style={{ color: PageDesignConstants.fontColor }}
                                                                    >
                                                                        {index_dt}
                                                                    </span>{" "}
                                                                    and{" "}
                                                                    <span
                                                                        style={{ color: PageDesignConstants.fontColor }}
                                                                    >
                                                                        {index_dt_plus_6mo}
                                                                    </span>
                                                                    )?
                                                                </span>
                                                            </div>
                                                            <div className="answer-pad-left">
                                                                <div className="option-without-input">
                                                                    <input
                                                                        type="radio"
                                                                        id="q10Death_6mo_post_avaSelect_1"
                                                                        name="q10Death_6mo_post_avaSelect"
                                                                        value={1}
                                                                        checked={
                                                                            ptDetails.q10Death_6mo_post_avaSelect === 1//"1"
                                                                        }
                                                                        onChange={HandleFieldChange}
                                                                    />
                                                                    <label htmlFor="q10Death_6mo_post_avaSelect_1">
                                                                        <span className="radio-text-padding">Yes</span>
                                                                    </label>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <input
                                                                        type="radio"
                                                                        id="q10Death_6mo_post_avaSelect_0"
                                                                        name="q10Death_6mo_post_avaSelect"
                                                                        value={0}
                                                                        checked={
                                                                            ptDetails.q10Death_6mo_post_avaSelect === 0//"0"
                                                                        }
                                                                        onChange={HandleFieldChange}
                                                                    />
                                                                    <label htmlFor="q10Death_6mo_post_avaSelect_0">
                                                                        <span className="radio-text-padding">No</span>
                                                                    </label>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <input
                                                                        type="radio"
                                                                        id="q10Death_6mo_post_avaSelect_99"
                                                                        name="q10Death_6mo_post_avaSelect"
                                                                        value={99}
                                                                        checked={
                                                                            ptDetails.q10Death_6mo_post_avaSelect === 99//"99"
                                                                        }
                                                                        onChange={HandleFieldChange}
                                                                    />
                                                                    <label htmlFor="q10Death_6mo_post_avaSelect_99">
                                                                        <span className="radio-text-padding">
                                                                            Unknown
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <ErrorField show={hardErrList.q10Empty} message={PatientScreening_Msgs.Q10} />
                                                            <ErrorField show={softErrList.q10Terminate} message={PatientScreening_Msgs.INELIGIBLE} />

                                                        </div>
                                                    )}
                                                {showQ9 && ptDetails.q10Death_6mo_post_avaSelect === 1 && (
                                                    <div className="question-bot-sapce single-dig-quest">
                                                        <div className="question question-weight">
                                                            <span>11.</span>
                                                            <span className="quest-text-pad">
                                                                Please provide the patient's year of death.
                                                            </span>
                                                        </div>
                                                        <div className="answer-pad-left">
                                                            <div className="" style={{ display: "flex" }}>
                                                                <input
                                                                    type="radio"
                                                                    id="q11Year_of_death"
                                                                    name="q11Year_of_death"
                                                                    //checked={ptDetails.deathYearUnk != null && !ptDetails.deathYearUnk ? ptDetails.yearOfDeath}
                                                                    //checked={ptDetails.q11Year_of_deathRadio && !q11Death_year_unk && Number(ptDetails.q11Year_of_death) != 0 && !isNaN(Number(ptDetails.q11Year_of_death))}
                                                                    checked={ptDetails.q11Year_of_deathRadio && !q11Death_year_unk}
                                                                    //ptDetails.q11Year_of_death !== "" && ptDetails.q11Year_of_death !== "YYYY"
                                                                    //checked={ptDetails.q11Year_of_death}
                                                                    onChange={HandleFieldChange}
                                                                />
                                                                <span
                                                                    className=""
                                                                    style={{ marginLeft: "9px" }}
                                                                ></span>
                                                                <DateControl
                                                                    hideDay={true}
                                                                    hideMonth={true}
                                                                    ctrlId={"q11Year_of_death"}
                                                                    HandleDateChange={HandleDateChange}
                                                                    defYear={defYearQ11}
                                                                    date={ptDetails.q11Year_of_death}
                                                                />
                                                            </div>

                                                            <div className="option-without-input">
                                                                <input
                                                                    type="radio"
                                                                    id="q11Death_year_unk"
                                                                    name="q11Death_year_unk"
                                                                    checked={ptDetails.q11Death_year_unk}//T or F
                                                                    onChange={HandleFieldChange}
                                                                />
                                                                <label htmlFor="q11Death_year_unk">
                                                                    <span className="radio-text-padding">
                                                                        Year of death is unknown
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <ErrorField show={hardErrList.q11Empty} message={PatientScreening_Msgs.Q11} />
                                                        <ErrorField show={hardErrList.q11LessThanIndexDt} message={PatientScreening_Msgs[102]} />
                                                        <ErrorField show={hardErrList.q11LessThanIndexYr} message={PatientScreening_Msgs[103]} />
                                                    </div>
                                                )}
                                                {(ptDetails.q13VisibleBasedonQ8) && (
                                                    <div className="question-bot-sapce single-dig-quest">
                                                        <div className="question question-weight">
                                                            <span>12.</span>
                                                            <span className="quest-text-pad">
                                                                Is the patient still seen at your clinic?
                                                            </span>
                                                        </div>
                                                        <div className="answer-pad-left">
                                                            <div className="option-without-input">
                                                                <input
                                                                    type="radio"
                                                                    id="q12Pt_still_at_clinicSelect_1"
                                                                    name="q12Pt_still_at_clinicSelect"
                                                                    value={1}
                                                                    checked={
                                                                        ptDetails.q12Pt_still_at_clinicSelect === 1 || ptDetails.q12Pt_still_at_clinicSelect === "1"
                                                                    }
                                                                    onChange={HandleFieldChange}
                                                                />
                                                                <label htmlFor="q12Pt_still_at_clinicSelect_1">
                                                                    <span className="radio-text-padding">Yes</span>
                                                                </label>
                                                            </div>
                                                            <div className="option-without-input">
                                                                <input
                                                                    type="radio"
                                                                    id="q12Pt_still_at_clinicSelect_0"
                                                                    name="q12Pt_still_at_clinicSelect"
                                                                    value={0}
                                                                    checked={
                                                                        ptDetails.q12Pt_still_at_clinicSelect === 0 || ptDetails.q12Pt_still_at_clinicSelect === "0"
                                                                    }
                                                                    onChange={HandleFieldChange}
                                                                />
                                                                <label htmlFor="q12Pt_still_at_clinicSelect_0">
                                                                    <span className="radio-text-padding">No</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <ErrorField show={hardErrList.q12Empty} message={PatientScreening_Msgs.Q12} />
                                                    </div>
                                                )}
                                                <div className="question-bot-sapce single-dig-quest"
                                                    style={{
                                                        display:
                                                            ptDetails.q12Pt_still_at_clinicSelect === 0 && ptDetails.q13VisibleBasedonQ8//&& (q9Pt_still_aliveSelect === 1 || q9Pt_still_aliveSelect === 99) 
                                                                ? ""
                                                                : "none",
                                                    }}>
                                                    <div className="question question-weight">
                                                        <span>13.</span>
                                                        <span className="quest-text-pad">
                                                            Please indicate the primary reason why the patient is
                                                            no longer with your clinic.
                                                        </span>
                                                    </div>
                                                    <div className="answer-pad-left">
                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                id="q13Pt_left_clinic_reasonSelect_1"
                                                                name="q13Pt_left_clinic_reasonSelect"
                                                                value={1}
                                                                checked={
                                                                    ptDetails.q13Pt_left_clinic_reasonSelect === 1//"1"
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <label htmlFor="q13Pt_left_clinic_reasonSelect_1">
                                                                <span className="radio-text-padding">
                                                                    Patient has moved out of the area
                                                                </span>
                                                            </label>
                                                        </div>

                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                name="q13Pt_left_clinic_reasonSelect"
                                                                id="q13Pt_left_clinic_reasonSelect_2"
                                                                value={2}
                                                                checked={
                                                                    ptDetails.q13Pt_left_clinic_reasonSelect === 2//"2"
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <label htmlFor="q13Pt_left_clinic_reasonSelect_2">
                                                                <span className="radio-text-padding">
                                                                    Patient has switched providers
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                name="q13Pt_left_clinic_reasonSelect"
                                                                id="q13Pt_left_clinic_reasonSelect_3"
                                                                value={3}
                                                                checked={
                                                                    ptDetails.q13Pt_left_clinic_reasonSelect === 3//"3"
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <label htmlFor="q13Pt_left_clinic_reasonSelect_3">
                                                                <span className="radio-text-padding">
                                                                    Patient has died
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                name="q13Pt_left_clinic_reasonSelect"
                                                                id="q13Pt_left_clinic_reasonSelect_4"
                                                                value={4}
                                                                checked={
                                                                    ptDetails.q13Pt_left_clinic_reasonSelect === 4//"4"
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <label htmlFor="q13Pt_left_clinic_reasonSelect_4">
                                                                <span className="radio-text-padding">
                                                                    Patient no longer requires treatment for groats
                                                                </span>
                                                            </label>
                                                        </div>

                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                name="q13Pt_left_clinic_reasonSelect"
                                                                id="q13Pt_left_clinic_reasonSelect_5"
                                                                value={5}
                                                                checked={
                                                                    ptDetails.q13Pt_left_clinic_reasonSelect === 5//"5"
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <label htmlFor="q13Pt_left_clinic_reasonSelect_5">
                                                                <span className="radio-text-padding">
                                                                    Other, please specify:{" "}
                                                                    <input
                                                                        name="q13Pt_left_clinic_reason_oth_txt"
                                                                        className="input-dash"
                                                                        type="text"
                                                                        value={
                                                                            ptDetails.q13Pt_left_clinic_reason_oth_txt
                                                                        }
                                                                        onChange={HandleFieldChange}



                                                                    />
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                name="q13Pt_left_clinic_reasonSelect"
                                                                id="q13Pt_left_clinic_reasonSelect_6"
                                                                value={6}
                                                                checked={
                                                                    ptDetails.q13Pt_left_clinic_reasonSelect === 6//"6"
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <label htmlFor="q13Pt_left_clinic_reasonSelect_6">
                                                                <span className="radio-text-padding">Unknown</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={hardErrList.q13Empty} message={PatientScreening_Msgs.Q13} />
                                                </div>


                                            </>
                                        }
                                        <div className="question-bot-sapce  single-dig-quest">
                                            <div className="question question-weight">
                                                <span>14.</span>
                                                <span className="quest-text-pad">
                                                Please indicate which of the following comorbidities
                                                    the patient was diagnosed with or had as an ongoing
                                                    condition in the 6 months prior to treatment X initiation.
                                                 </span>
                                            </div>
                                            <div className=" q14-padding-temporary">
                                                <div className="">
                                                    <input
                                                        type="checkbox"
                                                        id="q14Comorb_cpd"
                                                        name="q14Comorb_cpd"
                                                        checked={ptDetails.q14Comorb_cpd}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q14Comorb_cpd">
                                                        <span className="radio-text-padding">
                                                            Chronic pulmonary disease
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="q14Comorb_diabetes"
                                                        name="q14Comorb_diabetes"
                                                        checked={ptDetails.q14Comorb_diabetes}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q14Comorb_diabetes">
                                                        <span className="radio-text-padding">
                                                            Diabetes{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="q14Comorb_renal_failure"
                                                        name="q14Comorb_renal_failure"
                                                        checked={ptDetails.q14Comorb_renal_failure}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q14Comorb_renal_failure">
                                                        <span className="radio-text-padding">
                                                            Renal failure{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="q14Comorb_vasc_events"
                                                        name="q14Comorb_vasc_events"
                                                        checked={ptDetails.q14Comorb_vasc_events}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q14Comorb_vasc_events">
                                                        <span className="radio-text-padding">
                                                            Vascular events{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="q14Comorb_hep_c"
                                                        name="q14Comorb_hep_c"
                                                        checked={ptDetails.q14Comorb_hep_c}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q14Comorb_hep_c">
                                                        <span className="radio-text-padding">
                                                            Hepatitis C virus{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="q14Comorb_hiv"
                                                        name="q14Comorb_hiv"
                                                        checked={ptDetails.q14Comorb_hiv}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q14Comorb_hiv">
                                                        <span className="radio-text-padding">
                                                            Human Immunodeficiency virus (HIV){" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="q14Comorb_any_malig"
                                                        name="q14Comorb_any_malig"
                                                        checked={ptDetails.q14Comorb_any_malig}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q14Comorb_any_malig">
                                                        <span className="radio-text-padding">
                                                            Any malignancy{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="q14Comorb_cld"
                                                        name="q14Comorb_cld"
                                                        checked={ptDetails.q14Comorb_cld}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q14Comorb_cld">
                                                        <span className="radio-text-padding">
                                                            Chronic liver disease{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="q14Comorb_hema_oth"
                                                        name="q14Comorb_hema_oth"
                                                        checked={ptDetails.q14Comorb_hema_oth}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q14Comorb_hema_oth">
                                                        <span className="radio-text-padding">
                                                            Hematological disorders besides groats, please specify{" "}
                                                            <input
                                                                name="q14Comorb_hema_oth_txt"
                                                                className="input-dash"
                                                                type="text"
                                                                value={ptDetails.q14Comorb_hema_oth_txt}
                                                                onChange={HandleFieldChange}
                                                            />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q14Comorb_none"
                                                        name="q14Comorb_none"
                                                        checked={ptDetails.q14Comorb_none}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q14Comorb_none">
                                                        <span className="radio-text-padding">
                                                            None of the above
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={hardErrList.q14Empty} message={PatientScreening_Msgs.Q14} />
                                            <ErrorField show={hardErrList.q14EmptyOtherTxt} message="Please specify the other comorbidities the patient was diagnosed with." />
                                        </div>


                                        {showBasedOnQ3Response &&
                                            <>
                                                <div className="question-bot-sapce  single-dig-quest">
                                                    <div className="question question-weight">
                                                        <span>15.</span>
                                                        <span className="quest-text-pad">
                                                        What was the patient’s weight at the earliest date
                                                            during the 3 months prior to treatment X initiation (
                                                            <span
                                                                style={{ color: PageDesignConstants.fontColor }}
                                                            >
                                                                {index_dt_minus_3mo}
                                                            </span>{" "}
                                                            to{" "}
                                                            <span
                                                                style={{ color: PageDesignConstants.fontColor }}
                                                            >
                                                                {index_dt}
                                                            </span>
                                                            )? If you don’t have the exact number, estimate based
                                                            on the information you have.{" "}
                                                        </span>
                                                    </div>
                                                    <div className="answer-pad-left">
                                                        <div className="option-without-input">
                                                            <span className="">
                                                                <input
                                                                    name="q15Pt_weight"
                                                                    className="sub-input-min-dash"
                                                                    type="text"
                                                                    value={ptDetails.q15Pt_weight}
                                                                    onChange={HandleFieldChange}
                                                                />{" "}
                                                                <select
                                                                    className="freq-dropdown"
                                                                    name="q15Pt_weight_unitSelect"
                                                                    style={{
                                                                        paddingBottom: "1px",
                                                                    }}
                                                                    value={ptDetails.q15Pt_weight_unitSelect}
                                                                    onChange={HandleFieldChange}
                                                                >
                                                                    <option value={0}>Select</option>
                                                                    {
                                                                        Object.entries(WeightUnit)?.map(([value, text]) => (
                                                                            <option key={value} value={value}>{text}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </span>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <input
                                                                type="radio"
                                                                id="q15Pt_weight_unk"
                                                                name="q15Pt_weight_unk"
                                                                checked={ptDetails.q15Pt_weight_unk}
                                                                onChange={HandleFieldChange}
                                                            //value={ptDetails.q15Pt_weight_unk}
                                                            />
                                                            <label htmlFor="q15Pt_weight_unk">
                                                                <span className="radio-text-padding">
                                                                    Patient weight is unknown
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={hardErrList.q15Empty} message={PatientScreening_Msgs.Q15} />
                                                    <ErrorField show={hardErrList.q15EmptyUnit} message={PatientScreening_Msgs.Q15Unit} />
                                                    <ErrorField show={softErrList.q15WeightCheck} message={PatientScreening_Msgs[110]} />
                                                </div>
                                            </>
                                        }

                                        {/*Q15 end*/}
                                    </div>
                                </div>
                            </div>

                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SaveForm} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PatientScreening;
