export const UserLoginMessage = {
    LogginFailure: "Login Failed. Incorrect Email and Password.",
    LogginSuccess: "User logged in",
    LoginEmailBlank: "Enter Email Address",
    LoginPasswordBlank: "Enter Password",
    LoginInvalidEmail: "Enter valid Email"
};
export const Abstractor_Msgs = {
    FIRSTNAME: "Enter first name",
    LASTNAME: "Enter last name",
    EMAIL_REQUIRED: "Enter email address",
    EMAIL_VALID: "Please enter a valid email",
    CENTER: "Please select center",
    REQUIRED: "This field is required",
    PASSWORD_VALID:
        "Your password must contain one lowercase letter, one uppercase letter, one number, and one symbol",
    EMAIL_EXISTS: "Email address already exists",
    CENTER_LOAD_ERROR: "Error occurred while loading centers",
    ADD_SUCCESS: "Abstractor added successfully",
    EDIT_SUCCESS: "Abstractor updated successfully",
    DELETE_SUCCESS: "Abstractor deleted successfully",
    DELETE_FAIL: "Error occurred when deleting abstractor",
    EMAIL_SENDOUT_SUCCESS: "Email sent successfully",//"An email has been sent to the email address regarding the further process",
    EMAIL_SENDOUT_ERROR: "Email address does not exists"
};

export const Center_Msgs = {
    NAME: "Enter center name",
    ADD_SUCCESS: "Center added successfully",
    EDIT_SUCCESS: "Center updated successfully",
    DELETE_SUCCESS: "Center deleted successfully",
    DELETE_FAIL: "Error occurred when deleting center",
};

export const ResetPasswordMessages = {
    PASSWORD_BLANK: "Enter Password",
    CONFIRM_PASSWORD_BLANK: "Re-Enter Password",
    PASSWORD_MISMATCH: "Passwords did not match!",
    PASSWORD_INVALID:
        "Your password should include at least one lowercase letter, one uppercase letter, one number and one symbol with a minimum length of eight characters.",
    LINK_EXPIRED: "Link has expired",
    EMAIL_NOTEXISTS: "Email address does not exists",
    //100:"Some error!!! "
};

export const CommonError_Msgs = {
    LOAD_ERR: "Error occurred while loading.",
    SAVE_ERR: "Error occurred while saving.",
    REQUIRED: "This field is required.",
    DATE_BEFORE_1920: "Date entered is before 1920.",
    Hard_Empty: "Please ensure that all mandatory fields are filled out before proceeding.",
}
export const PatientScreening_Msgs = {
    EligiblePatient: "This patient chart meets the study eligibility criteria. Please proceed to the next section of the chart abstraction form.",
    IneligiblePatient: "Thank you for entering the information, however the selected patient chart is not eligible for this study. No further information for this patient will be collected.",
    INELIGIBLE: "This patient is not eligible",
    //hard
    //q2
    100: "The year entered for the patient's primary groats diagnosis is before the patient's year of birth. Please revise your response.",
    //q8
    101: "The year entered for the latest date of the patient's complete medical records is after the patient's year of death. Please revise your response.",
    //q11
    102: "The date entered for the patient's date of death is before the date of treatment X initiation. Please revise your response.",
    103: "The year entered for the patient's death is before the year of treatment X initiation. Please revise your response.",
    //q3 Q3IndexLessThandiagnosis  
    111: "The date of treatment initiation with treatment X is before the date of primary treatment X diagnosis. Please revise your response.",

    //soft
    //q3
    104: "The date entered for treatment X initiation is before July 2019. Please revise your response.",
    105: "The date entered for treatment X initiation is after March 2023. Please revise your response.",
    106: "Please confirm that the date of treatment X initiation for this patient is unknown. Otherwise, please revise your response.",
    107: "Please confirm that this patient was not treated with treatment X. Otherwise, please revise your response.",
    108: "The patient must be 18 years or older at the time of treatment X initiation. Please review your records and edit your response if needed.",
    //q8
    109: `Based on the date that was inputted for the end date of complete medical records, this patient does not have at least 6 months of follow-up time following the date of treatment X initiation. Please revise your response if necessary.`,
    //q15
    110: "Please confirm the patient’s weight before proceeding.",

    //When field are left blank(Hard errors)
    Q1: "Please select the year of birth.",
    Q1InvalidYear: "Patient must be at least 18 years of age at the date of treatment X initiation",
    Q2: "Please select the date of primary treatment X diagnosis.",
    Q3: "Please select the date of treatment initiation with treatment X.",
    Q4: "Please specify if the patient has complete medical records for at least 3 months before they initiated treatment X.",
    Q5: "Please specify if the patient has at least one platelet count record.",
    Q6: "Please sepcify if the patient has participated in any previous treatment X clinical trials, including the treatment X-401 study.",
    Q7: " Please specify if the patient has complete medical records for at least 6 months following treatment X initiation.",
    Q8: "Please specify the latest date that you have complete medical records for this patient.",
    Q9: "Please specify if the patient is alive.",
    Q10: " Please specify if the patient die within 6 months of treatment X initiation.",
    Q11: "Please select patient’s year of death.",
    Q12: " Please specify if the patient is still seen at your clinic.",
    Q13: "Please specify the primary reason why the patient is no longer with your clinic.",
    Q14: "Please specify the comorbidities the patient was diagnosed with.",
    Q15: "Please specify the patient’s weight.",

    Q15Unit: "Please specify the unit.",
    //Date val. hard err
    Q2AfterToday: "The date you entered for date of primary treatment X diagnosis is after today's date. Please revise your response.",
    Q3AfterToday: "The date you entered for date of treatment X initiation is after today's date. Please revise your response.",
    Q8AfterToday: "The date you entered for latest date of complete medical records is after today’s date. Please revise your response.",

    DateChangeAlert: "The dates modified could impact the validation in treatment X treatment page. Please resave treatment X treatment page.",
    Q8ABeforeQ3Date:"The date entered for latest date of complete medical records is before the date of treatment X initiation. Please revise your response."
}

export const MessageRequired_Msg = {
    Q24: "please select any one option for treatment",
    Required: "This field is required."
}


export const AvaTreatment_Msgs = {
    DateAVAdiscontinue: "The date entered for treatment X discontinuation is on or before the date of treatment X initiation. Please revise your response.",
    DateDosageChange: "The date entered for the treatment X dosage and/or frequency change is on or before the date of treatment X initiation. Please revise your response. ",
    PlateletlessthanIndex: "The date entered for this platelet count is earlier than 3 months prior to the date of treatment X initiation. Please revise your response.",
    PlateletmorethanAVAdisc: "The date entered for this platelet count is after the patient's date of treatment X discontinuation. Please revise your response.",
    PlateletmorethanEndFollowup: "The date entered for this platelet count is after the end of available patient medical records. Please revise your response.",
    Q31: "Please specify if treatment X was used as 1st line treatment for groats.",
    Q32a: "Please specify the reasons for initiating treatment X for the treatment of primary groats.",
    Q32b: "Please specify the primary reason for initiating treatment X.",
    Q33: "Please specify the patient’s starting treatment X dosage and frequency.",
    Q34a: "Please select the Date of change.",
    Q34b: "Please specify the New dosage.",
    Q34c: "Please specify the New frequency.",
    Q34d: "Please specify the primary reason for the change in treatment X dosage and / or frequency.",
    Q35: "Please specify if the patient discontinue treatment X.",
    Q35a: "Please specify the date of discontinuation.",
    Q36: "Please specify the primary reason for discontinuing treatment X treatment.",
    Q37a: "Please specify the platelet count dates.",
    Q37b: "Please specify the platelet count values.",
    QdosageFreq: "Please only input values between 1 - 1500",
    Qplatelet: "Please only input values between 0 - 2000",
    Q35BeforeDoseDate: "The date of discontinuation inputted for treatment X is on or before one of the dates of treatment X dose changes. Please revise your response for either the date of discontinuation or an treatment X dose change.",
    Q37EmptyPlatelet: "Please enter platelet count for the date entered or delete the row if you have no other records of patient’s platelet counts.",
    Q37EmptyDate: "Please enter the date this platelet count was recorded on or delete the row if you have no other records of patient’s platelet counts.",
    Q32EmptyOtherText: "Please specify the other value.",
    Q38a: "Please specify the platelet transfusion dates.",
    TransfusionlessthanIndex: "The date entered for this platelet transfusion is earlier than 3 months prior to the date of treatment X initiation. Please revise your response.",
    TransfusionmorethanAVAdisc: "The date entered for this platelet transfusion is after the patient's date of treatment X discontinuation. Please revise your response.",
    TransfusionmorethanEndFollowup: "The date entered for this platelet transfusion is after the end of available patient medical records. Please revise your response.",
    FutureDate: "The date you entered for is after today’s date. Please revise your response.",
    TansfusionUnknwnSelected: "Please confirm that the patient did not have any platelet transfusions during the time period of interest. Please note that, if you confirm, any information entered in the table will be cleared.",
    Q34DateInvalidSoft: "The date of change for Dose/Frequency is after the end of available patient medical records. Please revise your response.",
    Q35DateInvalidSoft: "The date of treatment X discontinuation is after the end of available patient medical records. Please revise your response.",

}

export const HRU_Msgs = {
    Q45: "Please specify if the patient have any groats-related hospitalizations",
    Q45RepeaterA: "Please enter date of admission of groats-related hospitalizations",
    Q45RepeaterADateInvalidSoft1: "The date entered for this groats-related hospitalization admission is earlier than 3 months prior to the date of treatment X initiation. Please revise your response.",
    Q45RepeaterADateInvalidSoft2: "The date entered for this groats-related hospitalization admission is after the patient's date of treatment X discontinuation. Please revise your response.",
    Q45RepeaterADateInvalidSoft3: "The date entered for this groats-related hospitalization admission is after the end of available patient medical records. Please revise your response.",

    Q45RepeaterB: "Please enter date of discharge of groats-related hospitalizations",
    Q45RepeaterBDateInvalidSoft1: "The date entered for this groats-related hospitalization discharge is earlier than the date of admission. Please revise your response.",
    Q45RepeaterBDateInvalidSoft2: "The date entered for this groats-related hospitalization admission is after the end of available patient medical records. Please revise your response.",

    Q46: "Please specify if the patient have any groats-related emergency room (ER) visits",
    Q46Repeater: "Please enter the date of groats-related emergency visits",
    Q46RepeaterADateInvalidSoft1: "The date entered for this groats-related ER visit is earlier than 3 months prior to the date of treatment X initiation. Please revise your response.",
    Q46RepeaterADateInvalidSoft2: "The date entered for this groats-related ER visit is after the patient's date of treatment X discontinuation. Please revise your response.",
    Q46RepeaterADateInvalidSoft3: "The date entered for this groats-related ER visit is after the end of available patient medical records. Please revise your response.",

    Q47: "Please specify if the patient have any groats-related outpatient (OP) visits",
    Q47Repeater: "Please enter the date of groats-related outpatient visits",
    Q47RepeaterADateInvalidSoft1: "The date entered for this groats-related OP visit is earlier than 3 months prior to the date of treatment X initiation. Please revise your response.",
    Q47RepeaterADateInvalidSoft2: "The date entered for this groats-related OP visit is after the patient's date of treatment X discontinuation. Please revise your response.",
    Q47RepeaterADateInvalidSoft3: "The date entered for this groats-related OP visit is after the end of available patient medical records. Please revise your response.",
    QdosageFreq: "Please only input values between 1 - 1500",
}

export const demographicScreen_Msgs = {
    Q16: "Please select the patient's sex.",
    Q17: "Please select  the patient's race/ethnicity.",
    Q17a: "Please specify the other value.",
    Q18: "Please specify the state the patient reside in.",
    Q19: "Please select highest level of education the patient has completed.",
    Q20: "Please select patient's household income.",
    Q21: "Please select patient's employment status.",
    Q22: "Please select type(s) of insurance does the patient currently have. ",
    Q23: "Please select patient's primary language spoken at home.",
    Q23a: "Please specify  the other value.",
}

export const ForgotPassword = {
    EmailNotExistent: "This Email Address has not been set up - Please contact your Administrator to set this up.",
    EmailSuccess: "An Email has been sent to you regarding the further process.",
    ErrorOccurred: "Error occurred."
};
export const CommonMsgs = {
    DeleteConfirmMsg: "Are you sure you want to delete this data?",
    SessionTimeout: "Your session has expired, you will be logged out now.",
    SubmitSuccess: "Patient Submitted Successfully"
}
export const PatientDashboard_Msgs = {
    ExportSuccess: "Data exported successfully",
    UnlockConfirmMsg: "Would you like to unlock and allow the abstractors to edit this patient?",
    UnlockSuccessMsg: "Patient Unlocked successfully",
}